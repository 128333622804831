import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { DEBUG } from '../config/debug';
import PropTypes from 'prop-types';
import { useIndustryList } from '../services/api';

const IndustriesFilter = ({ activeFilters, onApplyFilters }) => {
  if (DEBUG.filters) {
    console.log('IndustriesFilter: Initializing with:', {
      activeFilters: activeFilters,
      hasApplyFilters: !!onApplyFilters
    });
  }

  // Fetch industries from API
  const { industries, isLoading, error } = useIndustryList();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState(activeFilters || []);
  
  const containerRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const dropdownRef = useRef(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] }}],
  });

  useEffect(() => {
    if (DEBUG.filters) {
      console.log('IndustriesFilter: activeFilters updated:', activeFilters);
    }
    setSelectedIndustries(activeFilters || []);
  }, [activeFilters]);

  const handleSelectAll = () => {
    setSelectedIndustries(industries.map(industry => industry.id));
  };

  const handleIndustryChange = (industryId) => {
    setSelectedIndustries(prev => 
      prev.includes(industryId) 
        ? prev.filter(id => id !== industryId)
        : [...prev, industryId]
    );
  };

  const handleApply = () => {
    if (DEBUG.filters) {
      console.log('IndustriesFilter: Applying filters:', {
        industries: selectedIndustries
      });
    }
    
    onApplyFilters({
      industries: selectedIndustries
    });
    setIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedIndustries(activeFilters || []);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <div
        ref={setReferenceElement}
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
      >
        <FontAwesomeIcon 
          icon={faIndustry} 
          className={`text-lg ${selectedIndustries.length > 0 ? 'text-blue-500' : 'text-gray-600'}`}
        />
      </div>

      {isOpen && createPortal(
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-72 bg-white shadow-lg ring-1 ring-black/5 rounded-lg z-50 divide-y divide-gray-100"
        >
          <div className="px-4 py-3 border-b">
            <div className="flex items-center justify-between">
              <label className="flex items-center gap-3 hover:bg-gray-50 rounded-md p-2 cursor-pointer transition-colors duration-200">
                <div className="flex items-center justify-center w-5 h-5">
                  <input
                    type="checkbox"
                    checked={selectedIndustries.length === industries.length && industries.length > 0}
                    onChange={handleSelectAll}
                    disabled={isLoading || industries.length === 0}
                    className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition-colors duration-200"
                  />
                </div>
                <span className="font-medium text-sm text-gray-700">SELECT ALL</span>
              </label>
              {isLoading && (
                <FontAwesomeIcon icon={faSpinner} className="text-blue-500 animate-spin" />
              )}
            </div>
          </div>

          <div className="max-h-60 overflow-y-auto py-2">
            {isLoading ? (
              <div className="flex justify-center items-center py-4">
                <FontAwesomeIcon icon={faSpinner} className="text-blue-500 animate-spin mr-2" />
                <span className="text-sm text-gray-500">Loading industries...</span>
              </div>
            ) : error ? (
              <div className="px-4 py-3 text-sm text-red-500">
                Error loading industries. Please try again later.
              </div>
            ) : industries.length === 0 ? (
              <div className="px-4 py-3 text-sm text-gray-500">
                No industries available.
              </div>
            ) : (
              industries.map((industry) => (
                <label
                  key={industry.id}
                  className="flex items-center gap-3 px-4 py-2 hover:bg-gray-50 cursor-pointer transition-colors duration-200"
                >
                  <div className="flex items-center justify-center w-5 h-5">
                    <input
                      type="checkbox"
                      checked={selectedIndustries.includes(industry.id)}
                      onChange={() => handleIndustryChange(industry.id)}
                      className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition-colors duration-200"
                    />
                  </div>
                  <span className="text-sm text-gray-700">{industry.label}</span>
                  {industry.count && (
                    <span className="text-xs text-gray-400 ml-auto">{industry.count} companies</span>
                  )}
                </label>
              ))
            )}
          </div>

          <div className="p-4 bg-gray-50 rounded-b-lg flex justify-between items-center">
            <div className="text-sm text-gray-500 font-medium">
              {selectedIndustries.length} Selected
            </div>
            <div className="flex gap-2">
              <button
                onClick={handleCancel}
                className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleApply}
                disabled={isLoading}
                className="px-4 py-1.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 font-medium disabled:bg-blue-300"
              >
                Apply
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

IndustriesFilter.propTypes = {
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  onApplyFilters: PropTypes.func.isRequired
};

IndustriesFilter.defaultProps = {
  activeFilters: []
};

export default React.memo(IndustriesFilter, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.activeFilters) === JSON.stringify(nextProps.activeFilters);
}); 