import React from 'react';

const ErrorBoundarySection = ({ children, error, isLoading }) => {
  if (isLoading) return (
    <div className="p-3 text-gray-500">Loading...</div>
  );
  
  if (error) return (
    <div className="p-3 text-red-500 text-sm">
      <span className="inline-block mr-2">⚠️</span>
      Unable to load data: {error.message || 'Unknown error'}
    </div>
  );
  
  return children;
};

export default ErrorBoundarySection;