import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faTriangleExclamation, faInfoCircle, faCheckCircle, faExclamationTriangle, faLightbulb, faShieldAlt } from "@fortawesome/free-solid-svg-icons"; 
import TradingRangeInsights from './TradingRangeInsights'; // Import the new component
import { Tooltip } from 'react-tooltip';
import './AiAnalysisPanel.css';

// Metric descriptions for tooltips
const METRIC_DESCRIPTIONS = {
  revenueGrowth: "Year-over-year growth in company's revenue",
  pegRatio: "Price/Earnings to Growth ratio - measures stock's value while considering company's growth",
  priceToSales: "Price-to-Sales ratio - compares company's stock price to its revenues",
  enterpriseToRevenue: "Enterprise Value to Revenue ratio - measures company's total value relative to its revenue",
  enterpriseToEbitda: "Enterprise Value to EBITDA ratio - measures company's total value relative to its earnings before interest, taxes, depreciation, and amortization",
  grossMargins: "Percentage of revenue remaining after cost of goods sold",
  operatingMargins: "Percentage of revenue remaining after operating expenses",
  trailingEps: "Earnings Per Share over the last 12 months",
  forwardEps: "Projected Earnings Per Share for the next 12 months",
  quickRatio: "Measure of company's ability to pay short-term obligations (excluding inventory)",
  currentRatio: "Measure of company's ability to pay short-term obligations",
  totalCashPerShare: "Total cash divided by number of shares outstanding",
  shortPercentOfFloat: "Percentage of freely traded shares that are sold short",
  beta: "Measure of stock's volatility compared to the overall market"
};

const METRIC_THRESHOLDS = {
  // Growth & Valuation
  revenueGrowth: {
    type: 'above',
    good: 15,  // >15% is good
    warning: 5, // 5-15% is warning
    // <5% is bad
  },
  pegRatio: {
    type: 'range',
    good: { min: 0.5, max: 1.5 },
    warning: { min: 0.25, max: 2.0 },
    // Outside range is bad
  },
  priceToSales: {
    type: 'below',
    good: 3,    // <3 is good
    warning: 5, // 3-5 is warning
    // >5 is bad
  },
  enterpriseToRevenue: {
    type: 'below',
    good: 3,
    warning: 5,
  },
  enterpriseToEbitda: {
    type: 'range',
    good: { min: 8, max: 15 },
    warning: { min: 5, max: 20 },
  },

  // Profitability & Margins  
  grossMargin: {
    type: 'above',
    good: 30,
    warning: 20,
  },
  operatingMargin: {
    type: 'above', 
    good: 15,
    warning: 10,
  },
  trailingEps: {
    type: 'above',
    good: 0,  // Profitable
  },
  forwardEps: {
    type: 'above',
    good: 0,  // Expected profitable
  },

  // Liquidity & Risk
  quickRatio: {
    type: 'range',
    good: { min: 1, max: 3 },
    warning: { min: 0.8, max: 4 },
  },
  currentRatio: {
    type: 'range',
    good: { min: 1.5, max: 3 },
    warning: { min: 1.2, max: 4 },
  },
  cashPerShare: {
    type: 'above',
    good: 1,
    warning: 0.5,
  },
  shortPercentOfFloat: {
    type: 'below',
    good: 5,     // <5% is good
    warning: 10, // 5-10% is warning
    // >10% is concerning
  },
  beta: {
    type: 'range',
    good: { min: 0.8, max: 1.2 },
    warning: { min: 0.5, max: 1.5 },
  }
};

const getMetricColor = (value, metricName) => {
  const threshold = METRIC_THRESHOLDS[metricName];
  if (!threshold || isNaN(value)) return '';

  const numValue = parseFloat(value);

  switch (threshold.type) {
    case 'above':
      if (numValue >= threshold.good) return 'positive';
      if (numValue >= threshold.warning) return 'warning';
      return 'negative';

    case 'below':
      if (numValue <= threshold.good) return 'positive';
      if (numValue <= threshold.warning) return 'warning';
      return 'negative';

    case 'range':
      if (numValue >= threshold.good.min && numValue <= threshold.good.max) return 'positive';
      if (numValue >= threshold.warning.min && numValue <= threshold.warning.max) return 'warning';
      return 'negative';

    default:
      return '';
  }
};

const MetricRow = ({ 
  label, 
  value, 
  tooltip, 
  isPercentage, 
  isCurrency,
  metricName,  // Add this prop
  analysis 
}) => {
  const id = `metric-${label.toLowerCase().replace(/\s+/g, '-')}`;
  
  let formattedValue = value;
  if (value === 'N/A') {
    formattedValue = 'N/A';
  } else if (isPercentage) {
    formattedValue = `${value}%`;
  } else if (isCurrency) {
    formattedValue = `$${value}`;
  }

  const colorClass = getMetricColor(value, metricName);
  const valueClass = `metric-value ${colorClass}`;

  return (
    <div className="metric-row">
      <div className="metric-label">
        {label}
        <FontAwesomeIcon 
          icon={faInfoCircle} 
          className="info-icon"
          data-tooltip-id={id}
        />
        <Tooltip 
          id={id}
          content={
            <>
              {tooltip}
              {analysis && (
                <>
                  <br/><br/>
                  <span className="font-medium">Analysis:</span> {analysis}
                </>
              )}
            </>
          }
          className="metric-tooltip"
        />
      </div>
      <div className={valueClass}>
        {formattedValue}
      </div>
    </div>
  );
};

const AiAnalysisPanel = ({ aiAnalysis, stock }) => {
  if (!aiAnalysis || !stock) {
    return <div>No AI Analysis or stock data available.</div>;
  }

  const {
    recommendation_rating,
    recommendation_explanation,
    summary_rating,
    analysis_data,
    updated_at,
  } = aiAnalysis;

  if (!analysis_data) {
    return <div>No detailed analysis data available.</div>;
  }

  // Get analytical comments from AI analysis if available
  const getAnalysis = (metric) => {
    if (analysis_data?.financial_analysis?.[metric]) {
      return analysis_data.financial_analysis[metric];
    }
    return null;
  };

  return (
    <>
      {/* Trading Range Insights */}
      <TradingRangeInsights stock={stock} />

      {/* Company Description */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-xl font-semibold mb-2">Company Description</h3>
        <p>{analysis_data.description}</p>
      </div>

      {/* Market Size and Growth */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-xl font-semibold mb-2">Market Size and Growth</h3>
        <p>{analysis_data.market_size_and_growth}</p>
      </div>

      {/* Financial Metrics Section */}
      <div className="financial-metrics">
        <h3 className="text-xl font-semibold mb-6">Financial Metrics</h3>
        <div className="financial-metrics-grid">
          {/* Growth & Valuation */}
          <div className="metric-group">
            <h4 className="metric-group-title">Growth & Valuation</h4>
            <MetricRow 
              label="Revenue Growth"
              value={stock.revenueGrowth ? (stock.revenueGrowth * 100).toFixed(1) : 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.revenueGrowth}
              isPercentage
              metricName="revenueGrowth"
              analysis={getAnalysis('revenue_growth')}
            />
            <MetricRow 
              label="PEG Ratio"
              value={stock.pegRatio?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.pegRatio}
              analysis={getAnalysis('peg_ratio')}
              metricName="pegRatio"
            />
            <MetricRow 
              label="P/S (TTM)"
              value={stock.priceToSalesTrailing12Months?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.priceToSales}
              analysis={getAnalysis('price_to_sales')}
              metricName="priceToSales"
            />
            <MetricRow 
              label="Enterprise/Revenue"
              value={stock.enterpriseToRevenue?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.enterpriseToRevenue}
              analysis={getAnalysis('enterprise_to_revenue')}
              metricName="enterpriseToRevenue"
            />
            <MetricRow 
              label="Enterprise/EBITDA"
              value={stock.enterpriseToEbitda?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.enterpriseToEbitda}
              analysis={getAnalysis('enterprise_to_ebitda')}
              metricName="enterpriseToEbitda"
            />
          </div>

          {/* Profitability & Margins */}
          <div className="metric-group">
            <h4 className="metric-group-title">Profitability & Margins</h4>
            <MetricRow 
              label="Gross Margin"
              value={stock.grossMargins ? (stock.grossMargins * 100).toFixed(1) : 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.grossMargins}
              isPercentage
              analysis={getAnalysis('gross_margin')}
              metricName="grossMargin"
            />
            <MetricRow 
              label="Operating Margin"
              value={stock.operatingMargins ? (stock.operatingMargins * 100).toFixed(1) : 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.operatingMargins}
              isPercentage
              analysis={getAnalysis('operating_margin')}
              metricName="operatingMargin"
            />
            <MetricRow 
              label="EPS (TTM)"
              value={stock.trailingEps?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.trailingEps}
              isCurrency
              analysis={getAnalysis('trailing_eps')}
              metricName="trailingEps"
            />
            <MetricRow 
              label="EPS (Forward)"
              value={stock.forwardEps?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.forwardEps}
              isCurrency
              analysis={getAnalysis('forward_eps')}
              metricName="forwardEps"
            />
          </div>

          {/* Liquidity & Risk */}
          <div className="metric-group">
            <h4 className="metric-group-title">Liquidity & Risk</h4>
            <MetricRow 
              label="Quick Ratio"
              value={stock.quickRatio?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.quickRatio}
              analysis={getAnalysis('quick_ratio')}
              metricName="quickRatio"
            />
            <MetricRow 
              label="Current Ratio"
              value={stock.currentRatio?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.currentRatio}
              analysis={getAnalysis('current_ratio')}
              metricName="currentRatio"
            />
            <MetricRow 
              label="Cash per Share"
              value={stock.totalCashPerShare?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.totalCashPerShare}
              isCurrency
              analysis={getAnalysis('cash_per_share')}
              metricName="cashPerShare"
            />
            <MetricRow 
              label="Short % of Float"
              value={stock.shortPercentOfFloat ? (stock.shortPercentOfFloat * 100).toFixed(2) : 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.shortPercentOfFloat}
              isPercentage
              analysis={getAnalysis('short_percent_of_float')}
              metricName="shortPercentOfFloat"
            />
            <MetricRow 
              label="Beta"
              value={stock.beta?.toFixed(2) || 'N/A'}
              tooltip={METRIC_DESCRIPTIONS.beta}
              analysis={getAnalysis('beta')}
              metricName="beta"
            />
          </div>
        </div>
      </div>

      {/* Competition */}
      {analysis_data.competition &&
        analysis_data.competition.competitors && (
          <div className="bg-white rounded-lg shadow p-6 mb-4">
            <h3 className="text-xl font-semibold mb-4">Competition</h3>
            <table className="min-w-full text-left text-sm">
              <thead>
                <tr className="border-b">
                  <th className="py-2 px-4 font-medium">Competitor</th>
                  <th className="py-2 px-4 font-medium">Differentiation</th>
                </tr>
              </thead>
              <tbody>
                {analysis_data.competition.competitors.map(
                  (competitor, index) => (
                    <tr key={index} className="border-b">
                      <td className="py-2 px-4 font-medium">
                        {competitor.name}
                      </td>
                      <td className="py-2 px-4">
                        {competitor.differentiation}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}

      {/* Growth Prospects */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-xl font-semibold mb-2">Growth Prospects</h3>
        <p>{analysis_data.growth_prospects}</p>
      </div>

      {/* SWOT Analysis */}
      {analysis_data.swot_analysis && (
        <div className="bg-white rounded-lg shadow p-6 mb-4">
          <h3 className="text-xl font-semibold mb-4">SWOT Analysis</h3>
          <div className="swot-grid">
            <div className="swot-item swot-strengths">
              <h4 className="swot-title">
                <FontAwesomeIcon icon={faCheckCircle} className="swot-icon" />
                Strengths
              </h4>
              <ul className="swot-list">
                {analysis_data.swot_analysis.strengths?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="swot-item swot-weaknesses">
              <h4 className="swot-title">
                <FontAwesomeIcon icon={faExclamationTriangle} className="swot-icon" />
                Weaknesses
              </h4>
              <ul className="swot-list">
                {analysis_data.swot_analysis.weaknesses?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="swot-item swot-opportunities">
              <h4 className="swot-title">
                <FontAwesomeIcon icon={faLightbulb} className="swot-icon" />
                Opportunities
              </h4>
              <ul className="swot-list">
                {analysis_data.swot_analysis.opportunities?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="swot-item swot-threats">
              <h4 className="swot-title">
                <FontAwesomeIcon icon={faShieldAlt} className="swot-icon" />
                Threats
              </h4>
              <ul className="swot-list">
                {analysis_data.swot_analysis.threats?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* Key Developments and Risk Factors */}
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        {/* Key Developments */}
        {analysis_data.key_developments && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Key Developments</h3>
            <ul className="list-disc list-inside text-gray-700">
              {analysis_data.key_developments.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Risk Factors */}
        {analysis_data.risk_factors && (
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Risk Factors</h3>
            <ul className="list-disc list-inside text-gray-700">
              {analysis_data.risk_factors.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Updated At */}
      <div className="ai-disclaimer-text flex items-center gap-2">
        <FontAwesomeIcon icon={faTriangleExclamation} className="ai-disclaimer-icon" />
        <p>Some data generated with help of LLM AI, subject to possible "hallucinations". Please do your own research. General company data last updated: {new Date(updated_at).toISOString().split('T')[0]}</p>
      </div>
    </>
  );
};

export default AiAnalysisPanel;