import React from 'react';
import { FiInfo } from 'react-icons/fi';
import { ResponsiveLine } from '@nivo/line';
import { useQuery } from "@tanstack/react-query";
import Tooltip from './Tooltip';
import DisclaimerMessage from './DisclaimerMessage.js';
import ErrorBoundarySection from './ErrorBoundarySection';
import GaugeChart from './GaugeChart';
import { publicFetch } from '../utils/authenticatedFetch';
import '../styles/FearGreed.css';

// Helper function for sentiment colors
const getSentimentColor = (rating) => {
  const colors = {
    'extreme fear': 'text-extreme-fear',
    'fear': 'text-fear',
    'neutral': 'text-neutral',
    'greed': 'text-greed',
    'extreme greed': 'text-extreme-greed'
  };
  return colors[rating?.toLowerCase()] || 'text-gray-600';
};

// Define sentiment ranges and values
const SENTIMENT_LEVELS = [
  'extreme fear',
  'fear',
  'neutral',
  'greed',
  'extreme greed'
];

// Add color constants for consistency
const INDICATOR_COLORS = {
  'Fear & Greed Index': '#6B7280',  // Main index - gray
  'Market Momentum': '#3B82F6',     // Blue 
  'Market Volatility': '#EF4444',   // Red
  'Stock Price Strength': '#10B981', // Green
  'Safe Haven Demand': '#8B5CF6',   // Purple
  'Put/Call Ratio': '#F59E0B'       // Amber
};

// Helper function to format metric names
const formatMetricName = (name) => {
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const INDICATOR_DESCRIPTIONS = {
  market_momentum_sp125: "Measures the short-term price momentum of S&P 500 stocks relative to the past 125 days",
  market_momentum_sp500: "Measures the medium-term price momentum of S&P 500 stocks relative to the past 500 days",
  market_volatility: "Based on the VIX, comparing current volatility to recent levels",
  safe_haven_demand: "Tracks demand for safe-haven assets like bonds and gold relative to riskier assets",
  junk_bond_demand: "Measures the spread between yields on junk bonds and investment grade bonds",
  put_call_options: "Compares volume of put options (bearish bets) to call options (bullish bets)",
  stock_price_strength: "Measures the number of stocks hitting 52-week highs vs. lows",
  stock_price_breadth: "Tracks the volume of advancing stocks compared to declining stocks"
};

const getSentimentDescription = (rating) => {
  const descriptions = {
    'extreme fear': 'Indicates extremely pessimistic market sentiment, historically a contrarian buy signal',
    'fear': 'Shows elevated market pessimism and risk aversion',
    'neutral': 'Balanced market sentiment without strong bias',
    'greed': 'Indicates elevated market optimism and risk appetite',
    'extreme greed': 'Shows extremely optimistic sentiment, historically a contrarian sell signal'
  };
  return descriptions[rating?.toLowerCase()] || '';
};

const CompactIndicator = ({ title, score, rating }) => {
  const formattedTitle = formatMetricName(title);
  const description = INDICATOR_DESCRIPTIONS[title] || '';
  const sentimentDesc = getSentimentDescription(rating);
  const colorClass = getSentimentColor(rating);
  
  const tooltipContent = (
    <div className="max-w-xs">
      <div className="font-medium mb-1">{formattedTitle}</div>
      <div className="text-sm mb-2">{description}</div>
      <div className="text-sm italic">{sentimentDesc}</div>
      <div className="mt-2 text-xs text-gray-500">Score: {score?.toFixed(1)} / 100</div>
    </div>
  );

  return (
    <div className="relative inline-block">
      <Tooltip text={tooltipContent}>
        <div className={`inline-flex items-center ${colorClass.replace('text', 'bg')} bg-opacity-10 rounded-full px-3 py-1 mr-2 last:mr-0`}>
          <span className="text-xs font-medium mr-1.5 whitespace-nowrap">
            {formattedTitle}:
          </span>
          <span className="text-sm font-semibold">
            {typeof score === 'number' ? score.toFixed(1) : 'N/A'}
          </span>
          <span className={`text-[0.7rem] font-medium ml-1.5 px-1.5 py-0.5 rounded-full ${colorClass} bg-opacity-20`}>
            {rating}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

// Update the SENTIMENT_MAPPING constant
const SENTIMENT_MAPPING = {
  'extreme fear': 0,
  'fear': 25,
  'neutral': 50,
  'greed': 75,
  'extreme greed': 100
};

// Add a helper array for the order of ratings:
const ratingLevelsOrdered = ['extreme fear', 'fear', 'neutral', 'greed', 'extreme greed'];

// A helper function to map a rating string to its numeric index
function ratingToIndex(rating) {
  const idx = ratingLevelsOrdered.indexOf(rating?.toLowerCase());
  return idx >= 0 ? idx : 2; // default to 'neutral' if something's off
}

// Helper function to transform historical data
const transformHistoricalData = (data) => {
  // Log the historical data structure
  console.log('Historical data:', data?.historical);

  if (!data?.historical?.fear_greed) {
    console.log('No historical data found');
    return [];
  }

  // Transform the data for the time series chart
  return [{
    id: "Fear & Greed Index",
    color: INDICATOR_COLORS['Fear & Greed Index'],
    data: data.historical.fear_greed
      .map(point => {
        try {
          return {
            x: point.date,  // Assuming the API returns a date field
            y: point.score, // Assuming the API returns a score field
            rating: point.rating
          };
        } catch (error) {
          console.error('Error processing point:', point, error);
          return null;
        }
      })
      .filter(Boolean)
      .sort((a, b) => new Date(a.x) - new Date(b.x))
  }];
};

// Update the debug info display to be more readable
const DebugInfo = ({ current, indicators }) => (
  <div className="text-xs text-gray-500 mb-4 p-2 bg-gray-50 rounded">
    <div>Overall Score: {current?.score?.toFixed(1) || 'N/A'}</div>
    <div>Overall Rating: {current?.rating || 'N/A'}</div>
    <div>Number of Indicators: {Object.keys(indicators || {}).length}</div>
  </div>
);

// Modify SERIES_CONFIG to only include the main fear and greed line
const SERIES_CONFIG = [
  { key: 'fear_greed', label: 'Fear & Greed', color: '#6B7280' }
];

// Add a component for the indicator snapshots
const IndicatorSnapshot = ({ indicators }) => {
  if (!indicators) return null;

  const getSentimentBgColor = (rating) => {
    const colors = {
      'extreme fear': 'bg-red-100 text-red-800',
      'fear': 'bg-orange-100 text-orange-800',
      'neutral': 'bg-gray-100 text-gray-800',
      'greed': 'bg-green-100 text-green-800',
      'extreme greed': 'bg-emerald-100 text-emerald-800'
    };
    return colors[rating?.toLowerCase()] || 'bg-gray-100 text-gray-800';
  };

  return (
    <div className="flex flex-wrap gap-2 mb-6">
      {Object.entries(indicators).map(([key, data]) => {
        const label = key.split('_').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
        
        return (
          <div 
            key={key} 
            className={`inline-flex items-center ${getSentimentBgColor(data.rating)} rounded-full px-3 py-1 text-sm`}
          >
            <span className="font-medium mr-2">{label}:</span>
            <span className="font-medium capitalize">{data.rating}</span>
          </div>
        );
      })}
    </div>
  );
};

const FearGreedSection = ({ fearAndGreedData }) => {
  const chartData = React.useMemo(() => {
    if (!fearAndGreedData?.historical) return [];

    const processed = SERIES_CONFIG.map(({ key, label, color }) => {
      const seriesData = fearAndGreedData.historical[key];
      
      if (!Array.isArray(seriesData) || seriesData.length === 0) {
        return null;
      }

      const processedData = {
        id: label,
        color,
        data: seriesData
          .map(point => ({
            x: new Date(point.x),
            y: point.y,
            rating: point.rating
          }))
          .sort((a, b) => a.x - b.x)
      };
      
      // Debug log
      console.log('Processed data points:', processedData.data.slice(-5));
      return processedData;
    }).filter(Boolean);

    // Debug log
    console.log('Final chart data:', processed);
    return processed;
  }, [fearAndGreedData]);

  if (!fearAndGreedData) return <div>Loading fear & greed data...</div>;

  // Get date range for x-axis
  const allDates = chartData.flatMap(series => series.data.map(d => d.x));
  const minDate = new Date(Math.min(...allDates));
  const maxDate = new Date(Math.max(...allDates));
  const monthsDiff = (maxDate.getFullYear() - minDate.getFullYear()) * 12 + 
                    (maxDate.getMonth() - minDate.getMonth());

  // Get current value from data or fallback to last historical value
  const currentScore = fearAndGreedData?.current?.score || chartData[0]?.data?.slice(-1)[0]?.score;
  const currentRating = fearAndGreedData?.current?.rating || chartData[0]?.data?.slice(-1)[0]?.rating;

  return (
    <ErrorBoundarySection>
      <div className="bg-white rounded-lg shadow p-4">
        <div className="fear-greed-header">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-semibold text-black">Fear & Greed Index</h2>
            <Tooltip text="The Fear & Greed Index measures market sentiment based on various technical indicators">
              <FiInfo className="text-gray-400 hover:text-gray-600 cursor-help" />
            </Tooltip>
          </div>
          <div className="fear-greed-value">
            <div className={`fear-greed-score-container ${getSentimentColor(currentRating)}`}>
              <span className="fear-greed-score">{currentScore?.toFixed(1) || 'N/A'}</span>
              <span className={`fear-greed-rating ${currentRating?.toLowerCase().replace(' ', '-')}`}>
                {currentRating}
              </span>
            </div>
          </div>
        </div>

        <div className="fear-greed-indicators-container">
          {Object.entries(fearAndGreedData?.indicators || {}).map(([key, indicator]) => (
            <CompactIndicator 
              key={key}
              title={key}
              score={indicator.score}
              rating={indicator.rating}
            />
          ))}
        </div>

        {/* Existing chart */}
        {chartData.length > 0 ? (
          <div className="mt-6 relative">
            <h3 className="text-lg font-semibold mb-2">Historical Trend</h3>
            <div style={{ height: 300 }} className="historical-chart">
              <ResponsiveLine
                data={chartData}
                margin={{
                  top: 20,
                  right: 160,
                  bottom: 70,
                  left: 140
                }}
                xScale={{
                  type: 'time',
                  useUTC: false,
                  precision: 'day'
                }}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 100
                }}
                axisLeft={{
                  tickValues: [0, 25, 50, 75, 100],
                  format: (val) => {
                    if (val === 0) return 'extreme fear';
                    if (val === 25) return 'fear';
                    if (val === 50) return 'neutral';
                    if (val === 75) return 'greed';
                    if (val === 100) return 'extreme greed';
                    return val;
                  },
                  legend: 'Sentiment Level',
                  legendOffset: -80,
                  legendPosition: 'middle'
                }}
                axisBottom={{
                  format: '%b %d',
                  tickValues: monthsDiff > 12 ? 'every month' : 'every 14 days',
                  tickRotation: -45,
                  legend: 'Date',
                  legendOffset: 55,
                  legendPosition: 'middle'
                }}
                tooltip={({ point }) => (
                  <div className="bg-white p-2 shadow rounded border border-gray-200">
                    <div className="font-medium">{point.serieId}</div>
                    <div className="text-sm">
                      Date: {new Date(point.data.x).toLocaleDateString()}
                    </div>
                    <div className="text-sm">
                      Rating: {point.data.rating}
                    </div>
                    <div className="text-sm">
                      Score: {point.data.y.toFixed(1)}
                    </div>
                  </div>
                )}
                enablePoints={false}
                enableGridX={true}
                enableGridY={true}
                colors={d => d.color}
                curve="monotoneX"
                lineWidth={2}
                legends={[{
                  anchor: 'right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 4,
                  itemDirection: 'left-to-right',
                  itemWidth: 140,
                  itemHeight: 18,
                  itemOpacity: 0.85,
                  symbolSize: 10,
                  symbolShape: 'circle'
                }]}
              />
            </div>
          </div>
        ) : (
          <div>No historical data to display</div>
        )}
      </div>
    </ErrorBoundarySection>
  );
};

export default FearGreedSection;