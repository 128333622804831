import React, { useState } from 'react';
import { publicFetch } from '../utils/authenticatedFetch';
import { createUserList, useUserLists } from '../services/api';
import CONFIG from '../config/config';
import './AIFilter.css';

const AIFilterModal = ({ onClose, onSuccess, mode = 'filter', loggedInUser }) => {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // Use optional chaining to avoid 'cannot read email of undefined'
  const userEmail = loggedInUser?.email || null; 
  const { createList } = useUserLists(userEmail);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!userEmail) {
      setError('You must be logged in to create lists.');
      return;
    }

    if (!prompt.trim()) {
      setError('Please enter a description');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Get AI analysis
      const aiResponse = await publicFetch(CONFIG.API_URLS.AI_FILTER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          prompt,
          isPortfolio: mode === 'portfolio'
        })
      });

      const result = await aiResponse.json();
      console.log('AI service returned:', result);
      
      if (!result.symbols || !Array.isArray(result.symbols)) {
        throw new Error('Invalid response format from AI filter');
      }

      // Create the list data
      const listData = {
        name: result.short_name || `${mode === 'portfolio' ? 'Portfolio' : 'AI'} Generated List`,
        tooltip: mode === 'portfolio'
          ? `Portfolio Analysis: ${result.explanation?.slice(0, 100)}...`
          : result.criteria_analysis?.slice(0, 100),
        description: mode === 'portfolio'
          ? result.criteria_analysis
          : result.explanation,
        short_description: result.explanation,
        type: mode === 'portfolio' ? 'portfolio' : 'ai_filter',
        symbols: mode === 'portfolio'
          ? [...result.symbols, ...(result.suggested_symbols || [])]
          : result.symbols,
        prompt: prompt,
        icon: mode === 'portfolio' ? 'analytics' : 'AutoAwesome',
        category_id: 'personal',
        is_public: false
      };

      console.log('Creating list with data:', listData);

      createList.mutate(listData, {
        onSuccess: async (savedList) => {
          console.log('List created successfully:', savedList);
          // Call onSuccess with the savedList for any UI updates needed
          // but don't create the list again
          onSuccess?.(savedList);
        },
        onError: (err) => {
          console.error('Error creating list:', err);
          setError(err.message || 'Failed to create list');
        }
      });
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setError(error.message || 'Failed to process request');
    } finally {
      setIsLoading(false);
    }
  };

  const placeholder = mode === 'portfolio' 
    ? "Enter your portfolio symbols (comma-separated) for analysis. Example: AAPL, MSFT, GOOGL"
    : "Example: Find technology companies whose revenue growth is accelerating. Mid market";

  return (
    <div className="w-96">
      <form onSubmit={handleSubmit} className="divide-y divide-gray-100">
        <div className="p-4">
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder={placeholder}
            className="mt-2 w-full px-3 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            rows={3}
          />
          {error && (
            <div className="mt-2 text-sm text-red-600 bg-red-50 p-2 rounded-md border border-red-200">
              {error}
            </div>
          )}
        </div>

        <div className="p-3 bg-gray-50 rounded-b-lg flex justify-between items-center">
          <div className="text-xs text-gray-500">
            {isLoading ? 'Processing...' : `AI will ${mode === 'portfolio' ? 'analyze your portfolio' : 'analyze your request'}`}
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-3 py-1 text-xs text-gray-600 hover:text-gray-800 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-3 py-1 text-xs bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Processing...' : `${mode === 'portfolio' ? 'Analyze Portfolio' : 'Create List'}`}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AIFilterModal; 