import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <section className="hero">
        <div className="hero-content">
          <div className="logo-section">
            <img src="/quantape.png" alt="QuantApe Logo" className="hero-logo" />
            <div>
              <h1>Beat Wall Street at Their Own Game</h1>
              <h2>AI-Powered Tools Previously Reserved for Hedge Funds</h2>
            </div>
          </div>
          
          <div className="hero-story">
            <p className="story-lead">
              Wall Street has dominated markets using advanced technology and data analytics. Now it's your turn.
            </p>
            <div className="story-points">
              <div className="story-point">
                <i className="bi bi-robot"></i>
                <h3>AI-Powered Edge</h3>
                <p>Access machine learning insights for smarter stock screening</p>
              </div>
              <div className="story-point">
                <i className="bi bi-graph-up-arrow"></i>
                <h3>Comprehensive Analysis</h3>
                <p>Technical signals, fundamental metrics, and market sentiment in one place</p>
              </div>
              <div className="story-point">
                <i className="bi bi-kanban"></i>
                <h3>Smart Screening</h3>
                <p>Customizable filters, curated lists, and sector insights for targeted research</p>
              </div>
            </div>
          </div>

          <div className="hero-stats">
            <div className="stat-card">
              <div className="stat-value">5K+</div>
              <div className="stat-label">Stocks Analyzed Daily</div>
            </div>
            <div className="stat-card">
              <div className="stat-value">50+</div>
              <div className="stat-label">Smart Filters</div>
            </div>
            <div className="stat-card">
              <div className="stat-value">24/7</div>
              <div className="stat-label">Market Monitoring</div>
            </div>
          </div>

          <button 
            className="cta-button"
            onClick={() => navigate('/app')}
          >
            Start Making Better Investment Decisions Today
          </button>
        </div>
      </section>

      <section className="market-analysis-preview">
        <div className="market-content">
          <h2>Market Analysis</h2>
          
          <div className="market-overview-card">
            <div className="market-header">
              <div className="market-indicators">
                <div className="indicator positive">
                  <i className="bi bi-arrow-up-right"></i>
                  <span>48% Advancing</span>
                </div>
                <div className="indicator negative">
                  <i className="bi bi-arrow-down-right"></i>
                  <span>52% Declining</span>
                </div>
                <div className="indicator neutral">
                  <i className="bi bi-circle-half"></i>
                  <span>Fear & Greed: 46.7 (neutral)</span>
                </div>
              </div>
            </div>

            <div className="market-metrics">
              <div className="metric-tile">
                <span className="metric-label">Market Momentum Sp125</span>
                <span className="metric-value fear">30.4</span>
                <span className="metric-tag">fear</span>
              </div>
              <div className="metric-tile">
                <span className="metric-label">Market Volatility</span>
                <span className="metric-value neutral">50.0</span>
                <span className="metric-tag">neutral</span>
              </div>
              <div className="metric-tile">
                <span className="metric-label">Safe Haven Demand</span>
                <span className="metric-value greed">60.4</span>
                <span className="metric-tag">greed</span>
              </div>
              <div className="metric-tile">
                <span className="metric-label">Stock Price Strength</span>
                <span className="metric-value extreme-fear">17.4</span>
                <span className="metric-tag">extreme fear</span>
              </div>
            </div>

            <div className="market-cycle">
              <h3>Market Cycle Phase</h3>
              <div className="cycle-phases">
                <div className="phase active">
                  <span className="phase-number">1</span>
                  <div className="phase-content">
                    <h4>EARLY RECOVERY</h4>
                    <p>LOW Confidence</p>
                    <div className="phase-details">
                      <span>Financials • Consumer Discretionary • Industrials</span>
                      <span>Leadership from rate-sensitive and cyclical sectors</span>
                    </div>
                  </div>
                </div>
                <div className="phase">
                  <span className="phase-number">2</span>
                  <div className="phase-content">
                    <h4>EXPANSION</h4>
                    <p>Technology • Materials • Energy</p>
                  </div>
                </div>
                <div className="phase">
                  <span className="phase-number">3</span>
                  <div className="phase-content">
                    <h4>LATE CYCLE</h4>
                    <p>Energy • Materials • Healthcare</p>
                  </div>
                </div>
                <div className="phase">
                  <span className="phase-number">4</span>
                  <div className="phase-content">
                    <h4>CONTRACTION</h4>
                    <p>Utilities • Consumer Staples • Healthcare</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="cta-section">
        <div className="cta-content">
          <div className="beta-badge">Early Access</div>
          <h2>Join Our Beta Community</h2>
          <p>Help shape the future of intelligent market analysis</p>
          <div className="beta-features">
            <div className="beta-feature">
              <i className="bi bi-star"></i>
              <div className="feature-text">
                <h4>Beta Program Benefits</h4>
                <p>Free access to all features during beta period</p>
              </div>
            </div>
            <div className="beta-feature">
              <i className="bi bi-people"></i>
              <div className="feature-text">
                <h4>Community Driven</h4>
                <p>Your feedback directly shapes our development</p>
              </div>
            </div>
          </div>
          <button 
            className="cta-button"
            onClick={() => navigate('/app')}
          >
            Start Using QuantApe
          </button>
          <p className="beta-note">No credit card or account required • Instant access</p>
        </div>
      </div>

      <section className="screener-categories">
        <div className="category-section">
          <h2>Smart Trading Categories</h2>
          
          <div className="category-row">
            <div className="category-group">
              <h3>Earnings Analysis</h3>
              <div className="category-items">
                <div className="category-item">
                  <i className="bi bi-arrow-down"></i>
                  <span>Down Since Earnings</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-graph-up"></i>
                  <span>Earnings Surprises</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-graph-down"></i>
                  <span>Most Shorted</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-file-earmark-text"></i>
                  <span>Recent Earnings Reports</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-arrow-up"></i>
                  <span>Up Since Earnings</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-calendar-event"></i>
                  <span>Upcoming Earnings</span>
                </div>
              </div>
            </div>

            <div className="category-group">
              <h3>Market Movers</h3>
              <div className="category-items">
                <div className="category-item">
                  <i className="bi bi-arrow-up-right"></i>
                  <span>Day Gainers</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-arrow-down-right"></i>
                  <span>Day Losers</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-clock"></i>
                  <span>Extended Hours Movers</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-newspaper"></i>
                  <span>In The News</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-graph-up"></i>
                  <span>Most Active</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-person-standing"></i>
                  <span>Small Cap Gainers</span>
                </div>
              </div>
            </div>

            <div className="category-group">
              <h3>Strategic Opportunities</h3>
              <div className="category-items">
                <div className="category-item">
                  <i className="bi bi-robot"></i>
                  <span>AI Beneficiaries</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-cpu"></i>
                  <span>CHIPS Act Beneficiaries</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-car-front"></i>
                  <span>Autonomous Driving & LIDAR</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-graph-up"></i>
                  <span>Candidates for Index Inclusion</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-lightning"></i>
                  <span>High Growth Potential</span>
                </div>
                <div className="category-item">
                  <i className="bi bi-cash-stack"></i>
                  <span>Value Opportunities</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="stock-analysis-preview">
        <div className="analysis-content">
          <h2>Comprehensive Stock Analysis</h2>
          
          <div className="analysis-demo">
            <div className="demo-header">
              <div className="stock-info">
                <img src="https://companylogos.quantape.com/MSFT.ico" alt="MSFT" className="stock-logo" />
                <div>
                  <h3>Microsoft (MSFT)</h3>
                  <a href="microsoft.com" className="company-link">microsoft.com</a>
                </div>
              </div>
              <div className="stock-metrics">
                <div className="metric">
                  <span className="value">447.20</span>
                  <span className="change positive">+8.97%</span>
                </div>
                <div className="metric">
                  <span className="label">Volume</span>
                  <span className="value">1.18B</span>
                  <span className="change positive">13.09%</span>
                </div>
              </div>
            </div>

            <div className="analysis-sections">
              <div className="analysis-section">
                <h4>Financial Metrics</h4>
                <div className="financial-metrics-grid">
                  {/* Growth & Valuation */}
                  <div className="metric-group">
                    <h5 className="metric-group-title">Growth & Valuation</h5>
                    <div className="metric-row">
                      <span className="metric-label">Revenue Growth <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value positive">16.0%</span>
                    </div>
                    <div className="metric-row">
                      <span className="metric-label">PEG Ratio <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value negative">2.34</span>
                    </div>
                    <div className="metric-row">
                      <span className="metric-label">P/S (TTM) <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value negative">12.14</span>
                    </div>
                  </div>

                  {/* Profitability & Margins */}
                  <div className="metric-group">
                    <h5 className="metric-group-title">Profitability & Margins</h5>
                    <div className="metric-row">
                      <span className="metric-label">Gross Margin <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value positive">69.3%</span>
                    </div>
                    <div className="metric-row">
                      <span className="metric-label">Operating Margin <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value positive">46.6%</span>
                    </div>
                    <div className="metric-row">
                      <span className="metric-label">EPS (TTM) <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value positive">$12.41</span>
                    </div>
                  </div>

                  {/* Liquidity & Risk */}
                  <div className="metric-group">
                    <h5 className="metric-group-title">Liquidity & Risk</h5>
                    <div className="metric-row">
                      <span className="metric-label">Quick Ratio <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value positive">1.16</span>
                    </div>
                    <div className="metric-row">
                      <span className="metric-label">Current Ratio <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value positive">1.30</span>
                    </div>
                    <div className="metric-row">
                      <span className="metric-label">Beta <i className="bi bi-info-circle"></i></span>
                      <span className="metric-value neutral">0.90</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="analysis-section">
                <h4>Trading Range Analysis</h4>
                <div className="range-analysis">
                  <div className="price-range">
                    <div className="range-label">
                      <span>52W Low</span>
                      <span>Current</span>
                      <span>52W High</span>
                    </div>
                    <div className="range-bar">
                      <div className="range-indicator" style={{left: '65%'}}></div>
                    </div>
                    <div className="range-values">
                      <span>386.58</span>
                      <span>447.20</span>
                      <span>468.35</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="analysis-section">
                <h4>SWOT Analysis</h4>
                <div className="swot-content compact">
                  <div className="swot-row">
                    <div className="swot-category">
                      <h5>Strengths</h5>
                      <div className="swot-items">
                        <div className="swot-item">Diversified revenue streams</div>
                        <div className="swot-item">Strong cloud capabilities</div>
                      </div>
                    </div>
                    <div className="swot-category">
                      <h5>Weaknesses</h5>
                      <div className="swot-items">
                        <div className="swot-item">High competition in cloud</div>
                        <div className="swot-item">Regulatory pressures</div>
                      </div>
                    </div>
                  </div>
                  <div className="swot-row">
                    <div className="swot-category">
                      <h5>Opportunities</h5>
                      <div className="swot-items">
                        <div className="swot-item">AI market expansion</div>
                        <div className="swot-item">Gaming growth</div>
                      </div>
                    </div>
                    <div className="swot-category">
                      <h5>Threats</h5>
                      <div className="swot-items">
                        <div className="swot-item">Tech regulation</div>
                        <div className="swot-item">Market competition</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="analysis-section">
                <h4>Key Developments</h4>
                <div className="key-developments">
                  <div className="development-item">
                    <span className="date">January 2024</span>
                    <p>Acquisition of Activision Blizzard completed</p>
                  </div>
                  <div className="development-item">
                    <span className="date">December 2023</span>
                    <p>Azure OpenAI Service expansion</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ai-lists-preview">
        <div className="ai-lists-content">
          <h2>AI-Powered List Generation</h2>
          
          <div className="ai-demo">
            <div className="ai-input">
              <div className="input-preview">
                <i className="bi bi-stars"></i>
                <div className="input-content">
                  <div className="prompt-example">
                    "Find technology companies whose revenue growth is accelerating. Mid market"
                  </div>
                  <div className="example-lists">
                    <div className="list-item">
                      <i className="bi bi-lightning-charge"></i>
                      <span>EV Boom</span>
                    </div>
                    <div className="list-item">
                      <i className="bi bi-shield-check"></i>
                      <span>Risk Alert</span>
                    </div>
                    <div className="list-item">
                      <i className="bi bi-bank"></i>
                      <span>Fintech Leaders</span>
                    </div>
                    <div className="list-item">
                      <i className="bi bi-graph-up"></i>
                      <span>High Revenue</span>
                    </div>
                    <div className="list-item">
                      <i className="bi bi-virus"></i>
                      <span>mRNA Vaccine</span>
                    </div>
                  </div>
                </div>
              </div>
              <button className="create-list-btn">
                <i className="bi bi-plus-circle"></i>
                Create List
              </button>
            </div>

            <div className="ai-features">
              <div className="ai-feature">
                <i className="bi bi-cpu"></i>
                <div className="feature-text">
                  <h3>Natural Language Processing</h3>
                  <p>Describe your investment strategy in plain English</p>
                </div>
              </div>
              <div className="ai-feature">
                <i className="bi bi-lightning"></i>
                <div className="feature-text">
                  <h3>Real-time Analysis</h3>
                  <p>Instant analysis of market conditions and opportunities</p>
                </div>
              </div>
              <div className="ai-feature">
                <i className="bi bi-graph-up"></i>
                <div className="feature-text">
                  <h3>Smart Filtering</h3>
                  <p>Advanced algorithms to match your specific criteria</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio-analysis">
        <div className="portfolio-content">
          <h2>AI-Powered Portfolio Intelligence</h2>
          
          <div className="portfolio-overview">
            <div className="portfolio-features">
              <div className="portfolio-feature">
                <div className="feature-header">
                  <i className="bi bi-pie-chart"></i>
                  <h3>Portfolio Health Score</h3>
                </div>
                <div className="feature-demo">
                  <div className="health-meter">
                    <div className="meter-value">87</div>
                    <div className="meter-label">Strong</div>
                  </div>
                  <div className="health-factors">
                    <div className="factor positive">
                      <i className="bi bi-check-circle"></i>
                      <span>Diversification</span>
                    </div>
                    <div className="factor positive">
                      <i className="bi bi-check-circle"></i>
                      <span>Growth Potential</span>
                    </div>
                    <div className="factor warning">
                      <i className="bi bi-exclamation-circle"></i>
                      <span>Sector Concentration</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="portfolio-feature">
                <div className="feature-header">
                  <i className="bi bi-graph-up-arrow"></i>
                  <h3>Performance Analysis</h3>
                </div>
                <div className="feature-demo">
                  <div className="performance-metrics">
                    <div className="metric">
                      <span className="metric-label">YTD Return</span>
                      <span className="metric-value positive">+24.3%</span>
                    </div>
                    <div className="metric">
                      <span className="metric-label">vs S&P 500</span>
                      <span className="metric-value positive">+8.7%</span>
                    </div>
                    <div className="metric">
                      <span className="metric-label">Risk Score</span>
                      <span className="metric-value">Medium</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="portfolio-feature">
                <div className="feature-header">
                  <i className="bi bi-lightbulb"></i>
                  <h3>AI-Powered Insights</h3>
                </div>
                <div className="feature-demo">
                  <div className="portfolio-insights">
                    <div className="insight">
                      <i className="bi bi-arrow-up-circle"></i>
                      <div className="insight-content">
                        <h4>Growth Opportunity</h4>
                        <p>3 holdings well-positioned for AI industry expansion</p>
                      </div>
                    </div>
                    <div className="insight">
                      <i className="bi bi-shield-check"></i>
                      <div className="insight-content">
                        <h4>Risk Alert</h4>
                        <p>Consider rebalancing tech sector exposure</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="technical-signals-showcase">
        <div className="signals-content">
          <h2>Technical Trading Signals</h2>
          <p className="signals-description">
            AI-powered technical analysis identifies high-probability trading setups
          </p>
          
          <div className="signal-types-grid">
            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-arrow-down-circle"></i>
                <h3>Gap Down Momentum</h3>
              </div>
              <p>Significant price gap below previous close with strong volume confirmation</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">76%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">2-3 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-arrow-counterclockwise"></i>
                <h3>Strong Oversold Reversal</h3>
              </div>
              <p>Price rebounds from oversold conditions with volume confirmation</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">72%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">3-5 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-arrow-clockwise"></i>
                <h3>Strong Overbought Reversal</h3>
              </div>
              <p>Price reversal from overbought levels with divergence confirmation</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">71%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">2-4 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-arrow-up-circle"></i>
                <h3>Support Bounce</h3>
              </div>
              <p>Price rebounds from established support level with volume surge</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">74%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">3-5 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-arrow-up-circle-fill"></i>
                <h3>Gap Up Momentum</h3>
              </div>
              <p>Strong upward price gap with above-average volume confirmation</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">77%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">2-3 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-x-circle"></i>
                <h3>Resistance Rejection</h3>
              </div>
              <p>Failed breakout attempt at key resistance with volume confirmation</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">73%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">2-4 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-check-circle"></i>
                <h3>Double Bottom Confirmation</h3>
              </div>
              <p>Classic reversal pattern completion with volume confirmation</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">75%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">5-7 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-x-circle-fill"></i>
                <h3>Death Cross</h3>
              </div>
              <p>50-day MA crosses below 200-day MA with strong volume</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">69%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">10-15 days</span>
                </div>
              </div>
            </div>

            <div className="signal-type-card">
              <div className="signal-header">
                <i className="bi bi-plus-circle-fill"></i>
                <h3>Golden Cross</h3>
              </div>
              <p>50-day MA crosses above 200-day MA with volume confirmation</p>
              <div className="signal-stats">
                <div className="stat">
                  <span className="label">Avg Success Rate</span>
                  <span className="value">70%</span>
                </div>
                <div className="stat">
                  <span className="label">Typical Timeframe</span>
                  <span className="value">10-15 days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="cta-section final-cta">
        <div className="cta-content">
          <div className="beta-badge">Beta Access</div>
          <h2>Start Making Better Investment Decisions</h2>
          <p className="value-prop">Professional-grade tools for individual investors</p>
          <div className="membership-tiers">
            <div className="tier active">
              <div className="tier-header">
                <span className="tier-label">Current Beta Period</span>
                <span className="tier-price">Free</span>
              </div>
              <div className="tier-features">
                <div className="tier-feature">
                  <i className="bi bi-check-circle-fill"></i>
                  <span>Full Platform Access</span>
                </div>
                <div className="tier-feature">
                  <i className="bi bi-check-circle-fill"></i>
                  <span>Community Support</span>
                </div>
                <div className="tier-feature">
                  <i className="bi bi-check-circle-fill"></i>
                  <span>Regular Updates</span>
                </div>
              </div>
            </div>
            <div className="tier future">
              <div className="tier-header">
                <span className="tier-label">After Beta</span>
                <span className="tier-price">$39/mo</span>
              </div>
            </div>
          </div>
          <button 
            className="cta-button"
            onClick={() => navigate('/app')}
          >
            Try It Now
          </button>
          <p className="access-note">Instant access • No credit card or account required</p>
        </div>
      </div>

      <footer className="landing-footer">
        <div className="landing-footer-content">
          <div className="landing-footer-left">
            <div className="landing-footer-logo">
              <img src="/quantape.png" alt="QuantApe Logo" className="landing-footer-logo-img" />
              <div className="landing-footer-logo-text">
                <h1 className="landing-footer-logo-name">QuantApe Screener</h1>
                <p className="landing-footer-logo-subtitle">Because insight isn't optional. It's everything.</p>
              </div>
            </div>
          </div>

          <div className="landing-footer-center">
            <h4>Features</h4>
            <nav className="landing-footer-nav">
              <a href="#market-analysis">Market Analysis</a>
              <a href="#technical-signals">Technical Signals</a>
              <a href="#economic-events">Economic Events</a>
              <a href="#custom-screeners">Custom Screeners</a>
            </nav>
          </div>

          <div className="landing-footer-right">
            <div className="landing-legal-links">
              <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=8ac60459-f389-4d0f-a4b5-76b5c78e54cb" 
                target="_blank" 
                rel="noopener noreferrer">
                Terms of Service
              </a>
              {" | "}
              <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ee43322a-156d-44a3-a834-e8caf6b4f304" 
                target="_blank" 
                rel="noopener noreferrer">
                Privacy Policy
              </a>
              {" | "}
              <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=62a50a19-b4b3-4842-8c3e-90c4e7b2bdbf" 
                target="_blank" 
                rel="noopener noreferrer">
                Cookie Policy
              </a>
            </div>

            <div className="landing-footer-discord-link">
              <a href="https://discord.gg/YyXZPZ5Qdy"
                target="_blank"
                rel="noopener noreferrer"
                title="Become part of First Principles Community of Intelligent Investors">
                <i className="bi bi-people-fill"></i>
                Join QuantApe Discord Server for News, Comments, Questions, Community.
              </a>
            </div>

            <div className="landing-copyright">
              © 2025 First Principles Analytics, LLC. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage; 