import React from 'react';
import MarketCapFilter from './MarketCapFilter';
import IndustriesFilter from './IndustriesFilter';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const FilterHeaderIcons = ({ activeFilters, onApplyFilters }) => {
  return (
    <div className="flex items-center gap-2">
      <div
        data-tooltip-id="filter-tooltip"
        data-tooltip-content="Filter by Market Cap ($)"
        data-tooltip-place="top"
      >
        <MarketCapFilter 
          activeFilters={activeFilters?.marketCap}
          onApplyFilters={onApplyFilters}
        />
      </div>
      <div
        data-tooltip-id="filter-tooltip"
        data-tooltip-content="Filter by Industry"
        data-tooltip-place="top"
      >
        <IndustriesFilter 
          activeFilters={activeFilters?.industries}
          onApplyFilters={onApplyFilters}
        />
      </div>
      <Tooltip id="filter-tooltip" place="top" offset={5} />
      {/* ... other icons ... */}
    </div>
  );
};

FilterHeaderIcons.propTypes = {
  activeFilters: PropTypes.shape({
    marketCap: PropTypes.arrayOf(PropTypes.string),
    industries: PropTypes.arrayOf(PropTypes.string)
  }),
  onApplyFilters: PropTypes.func.isRequired
};

export default FilterHeaderIcons; 