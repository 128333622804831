import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AiAnalysisPanel from './AiAnalysisPanel';
import { fetchScreenData } from '../services/api';
import { Helmet } from 'react-helmet';
import CONFIG from '../config/config';
import './StockPage.css';

function StockPage() {
  const { ticker } = useParams();
  const [stockData, setStockData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // STEP 1: news-related states
  const [news, setNews] = useState([]);
  const [hasNews, setHasNews] = useState(false);
  const [relevantNews, setRelevantNews] = useState([]);

  useEffect(() => {
    if (!ticker) {
      setError('No ticker provided');
      setLoading(false);
      return;
    }

    (async () => {
      try {
        const result = await fetchScreenData(null, null, [ticker]);
        if (!result || result.length === 0) {
          setError('Ticker not found or no data returned.');
        } else {
          setStockData(result[0]);
        }

        // STEP 2: use config for the news endpoint
        const newsUrl = `${CONFIG.API_URLS.NEWS}?symbol=${ticker}`;
        const res = await fetch(newsUrl);

        if (!res.ok) {
          console.error('Error fetching news:', res.status, res.statusText);
          // you might optionally set an error or skip
        } else {
          const rawText = await res.text();
          console.log('[News raw response]', rawText);
          
          // Optionally parse
          const newsData = JSON.parse(rawText);
          setNews(newsData.articles || []);
        }
      } catch (err) {
        setError(`Failed to load data for ${ticker}: ${err.message}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [ticker]);

  useEffect(() => {
    if (!news.length) {
      setHasNews(false);
      setRelevantNews([]);
      return;
    }

    const filtered = news
      .filter(article => {
        // Step 2: parse tickers so we have an array
        let parsedTickers = [];
        try {
          if (typeof article.tickers === 'string') {
            parsedTickers = JSON.parse(article.tickers) || [];
          } else if (Array.isArray(article.tickers)) {
            parsedTickers = article.tickers;
          }
        } catch (err) {
          console.error('Error parsing tickers for:', article.url, err);
        }
        return parsedTickers.some(sym =>
          sym.replace(/[$#]/g, '').toUpperCase() === ticker.toUpperCase()
        );
      })
      .slice(0, 5); // top 5

    setRelevantNews(filtered);
    setHasNews(filtered.length > 0);
  }, [news, ticker]);

  const formatMarketCap = (marketCap) => {
    if (!marketCap) return 'N/A';
    if (marketCap >= 1e12) return `$${(marketCap / 1e12).toFixed(2)}T`;
    if (marketCap >= 1e9) return `$${(marketCap / 1e9).toFixed(2)}B`;
    if (marketCap >= 1e6) return `$${(marketCap / 1e6).toFixed(2)}M`;
    return `$${marketCap.toLocaleString()}`;
  };

  const formatLargeNumber = (num) => {
    if (!num) return 'N/A';
    return num.toLocaleString();
  };

  if (loading) {
    return <div className="p-4">Loading stock data for {ticker}...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-600">{error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{`${stockData.name} (${ticker}) - Stock Analysis`}</title>
        <meta name="description" content={stockData.description} />
      </Helmet>

      <div className="max-w-5xl mx-auto p-4">
        <div className="stock-header">
          <div className="stock-header-content">
            <div className="stock-logo-and-info">
              <div className="stock-logo">
                <img 
                  src={stockData.logo_url || '/default-logo.png'} 
                  alt={`${stockData.name} logo`}
                  onError={(e) => {
                    e.target.src = '/default-logo.png';
                  }}
                />
              </div>
              
              <div className="stock-info">
                <div className="stock-title">
                  <h1>{stockData.name}</h1>
                  <div className="stock-price">
                    <span className="current-price">${stockData.stockPrice?.toFixed(2)}</span>
                    <span className={`price-change ${stockData.stockChange >= 0 ? 'positive' : 'negative'}`}>
                      {stockData.stockChange > 0 ? '+' : ''}{stockData.stockChange?.toFixed(2)}%
                    </span>
                  </div>
                </div>
                <div className="stock-subtitle">
                  <span className="ticker">{ticker}</span>
                  <span className="industry">{stockData.industry}</span>
                  {stockData.website && (
                    <a 
                      href={stockData.website} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      title={stockData.website}
                      className="website-link"
                    >
                      <i className="fas fa-external-link-alt"></i>
                      Website
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="stock-metrics">
              <div className="metric">
                <span>Market Cap</span>
                <strong>{formatMarketCap(stockData.marketCap)}</strong>
              </div>
              <div className="metric">
                <span>Employees</span>
                <strong>{formatLargeNumber(stockData.fullTimeEmployees)}</strong>
              </div>
              <div className="metric">
                <span>P/E Ratio</span>
                <strong>{stockData.trailingPE?.toFixed(2) || 'N/A'}</strong>
              </div>
              <div className="metric">
                <span>Beta</span>
                <strong>{stockData.beta?.toFixed(2) || 'N/A'}</strong>
              </div>
            </div>
          </div>
        </div>

        {hasNews && (
          <div className="stock-section">
            <h2 className="section-title mb-4">Recent News</h2>
            <div className="company-news-container">
              {relevantNews.map((article) => (
                <a
                  key={article.url}
                  href={article.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="company-news-item flex gap-2 mb-2"
                >
                  {article.imageUrl && (
                    <img
                      src={article.imageUrl}
                      alt=""
                      className="company-news-image w-16 h-16 object-cover"
                    />
                  )}
                  <div className="company-news-text">
                    <h4 className="company-news-title font-semibold line-clamp-1">
                      {article.title}
                    </h4>
                    <div className="company-news-meta text-sm text-gray-500">
                      {article.source} • {new Date(article.publishedAt).toLocaleDateString()}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}

        <AiAnalysisPanel
          aiAnalysis={stockData.ai_analysis}
          stock={stockData}
        />

        <div className="stock-section">
          <h2 className="section-title mb-4">Company Description</h2>
          <p className="stock-description">
            {stockData.description}
          </p>
        </div>
      </div>
    </>
  );
}

export default StockPage; 