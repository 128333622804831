import React, { useState, useMemo, useCallback } from "react";
import { FiChevronUp, FiChevronDown, FiSettings } from "react-icons/fi";
import CompanyRow from "./CompanyRow";
import EconomicEventRow from "./EconomicEventRow";
import EconomicEventControls from "./EconomicEventControls";
import Tooltip from './Tooltip';
import SortableHeader from './SortableHeader';
import ColumnSettingsModal from './ColumnSettingsModal';
import Modal from 'react-modal';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  useSortable,
  arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { estimateNextEarningsDate } from "./utils";
import { ALL_COLUMNS } from '../config/columnConfig';

// Define the sanitizeId function
function sanitizeId(id) {
  const sanitized = id.replace(/[^a-zA-Z0-9-_]/g, '');
  console.log(`Original ID: ${id}, Sanitized ID: ${sanitized}`);
  return sanitized;
}

Modal.setAppElement('#root'); // Add this line if not already set

const formatJsonContent = (text) => {
  try {
    // Split the text into description and sections
    const [description, sectorAnalysis, riskMetrics] = text.split(/Sector Analysis:|Risk Metrics:/);
    
    if (!sectorAnalysis || !riskMetrics) return <span>{text}</span>;

    // Parse the JSON parts
    const sectorData = JSON.parse(sectorAnalysis.replace(/'/g, '"').trim());
    const riskData = JSON.parse(riskMetrics.replace(/'/g, '"').trim());

    return (
      <div className="explanation-content">
        <div className="description">{description.trim()}</div>
        <div className="metric-row">
          <span>Current:</span>
          {Object.entries(sectorData.current).map(([sector, value]) => (
            <span key={sector} className={sector.toLowerCase()}>
              {sector} {value}%
            </span>
          ))}
          <span className="spacer">|</span>
          <span>Suggested:</span>
          {Object.entries(sectorData.suggested).map(([sector, value]) => (
            <span key={sector} className={sector.toLowerCase()}>
              {sector} {value}%
            </span>
          ))}
        </div>
        <div className="metric-row">
          <span>Diversification Score: </span>
          <span className="score">{riskData.diversification_score}</span>
          <span className="risk-tag">Low</span>
          <span className="spacer">|</span>
          <span>Sector Concentration: </span>
          <span className="score">{riskData.sector_concentration}</span>
          <span className="risk-tag">High Risk</span>
          <span className="spacer">|</span>
          <span className="risks">
            {riskData.key_risks.map((risk, i) => 
              `${i > 0 ? ' • ' : '• '}${risk}`
            )}
          </span>
        </div>
      </div>
    );
  } catch (error) {
    console.error('Error parsing explanation:', error, text);
    return <span>{text}</span>;
  }
};

const StockTable = React.memo(({
  companyData = [],
  economicEvents = [],
  econAnalysis = null,
  onAddToWatchList,
  onDelete,
  loadingTable,
  selectedTimeframes,
  setSelectedTimeframes,
  geography,
  setGeography,
  showEconomicEvents,
  setShowEconomicEvents,
  selectedTab,
  colorSettings,
  columns, 
  onColumnReorder,
  onColorSettingsClick,
  userSettings,
  onSettingsChange,
  news = [],
  listExplanation = '',
  onNoteChange,
  onNotInterested,
}) => {
  // Ensure the actions column is only added if not already present
  const actionColumn = ALL_COLUMNS.find((col) => col.key === 'actions');
  const notesColumn = ALL_COLUMNS.find((col) => col.key === 'notes');
  
  // Start with the user's configured columns
  let displayColumns = [...columns];
  
  // Add the actions column if not already present
  if (!displayColumns.some((col) => col.key === 'actions')) {
    displayColumns.push(actionColumn);
  }
  
  // For watchlist tab, conditionally add the notes column if not already present
  if (selectedTab === 'watchlist' && !displayColumns.some((col) => col.key === 'notes')) {
    // Add notes column to the end
    displayColumns.push(notesColumn);
  }
  
  // Make sure to remove notes column for non-watchlist tabs
  if (selectedTab !== 'watchlist') {
    displayColumns = displayColumns.filter(col => col.key !== 'notes');
  }
  
  const displayedColumns = displayColumns;

  // Add debug logging
  console.log('StockTable economicEvents:', economicEvents);

  // Initialize sortConfig based on the first column
  const [sortConfig, setSortConfig] = useState({
    key: columns[0]?.sortField || columns[0]?.key || 'date',
    direction: 'ascending',
  });
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = useCallback((row) => {
    setExpandedRow(prevRow => prevRow === row ? null : row);
  }, []);

  const requestSort = useCallback((key) => {
    const column = displayedColumns.find((col) => col.sortField === key || col.key === key);
    if (column && column.isSortable) {
      setSortConfig((prevConfig) => {
        const direction =
          prevConfig.key === key && prevConfig.direction === 'ascending'
            ? 'descending'
            : 'ascending';
        return { key, direction };
      });
    }
  }, [displayedColumns]);

  const combinedData = useMemo(() => {
    // console.log('Processing company data:', companyData);

    const companyRows = companyData.map((company) => ({
      ...company,
      date: company.earningsDate || estimateNextEarningsDate(company.lastEarningsDate) || null,
      type: "company",
    }));

    const economicRows = economicEvents.map((event) => ({
      ...event,
      type: "economic",
    }));

    // Don't pre-sort here; let sortFunction handle it
    return [...companyRows, ...economicRows];
  }, [companyData, economicEvents]);

  const sortedData = useMemo(() => {
    console.log('Sorting data:', combinedData);
    return [...combinedData].sort((a, b) => {
      const key = sortConfig.key;
      const column = displayedColumns.find(col => col.sortField === key || col.key === key);
      
      // Use column's custom sortFunction if available
      if (column?.sortFunction) {
        return sortConfig.direction === 'ascending' 
          ? column.sortFunction(a, b)
          : column.sortFunction(b, a);
      }

      // Fallback to default sorting logic
      let aValue = a[key];
      let bValue = b[key];

      // Handle undefined or null values
      if (aValue == null) aValue = '';
      if (bValue == null) bValue = '';

      const aValueType = typeof aValue;
      const bValueType = typeof bValue;

      if (aValueType === 'string' && bValueType === 'string') {
        return sortConfig.direction === 'ascending'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (aValueType === 'number' && bValueType === 'number') {
        return sortConfig.direction === 'ascending'
          ? aValue - bValue
          : bValue - aValue;
      } else {
        // Convert both values to strings for comparison
        const aString = aValue.toString();
        const bString = bValue.toString();
        return sortConfig.direction === 'ascending'
          ? aString.localeCompare(bString)
          : bString.localeCompare(aString);
      }
    });
  }, [combinedData, sortConfig, displayedColumns]);

  const dateColumnStyle = useMemo(() => ({
    width: "90px",
    minWidth: "90px",
    maxWidth: "90px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }), []);

  const renderCompanyRow = useCallback(
    (item, index) => (
      <CompanyRow
        key={item.tickerSymbol || `company-${index}`}
        company={item}
        colorSettings={colorSettings}
        expandedRow={expandedRow}
        toggleRow={toggleRow}
        onAddToWatchList={onAddToWatchList}
        onDelete={onDelete}
        onNotInterested={onNotInterested}
        dateColumnStyle={dateColumnStyle}
        selectedTab={selectedTab}
        columns={displayedColumns}
        news={news}
        onNoteChange={onNoteChange}
      />
    ),
    [expandedRow, toggleRow, onAddToWatchList, onDelete, onNotInterested, dateColumnStyle, 
     selectedTab, colorSettings, displayedColumns, news, onNoteChange]
  );

  const renderEconomicEventRow = useCallback((event, index) => (
    <EconomicEventRow
      key={`economic-${index}`}
      event={event}
      expandedRow={expandedRow}
      toggleRow={toggleRow}
      index={index}
      dateColumnStyle={dateColumnStyle}
      totalColumns={displayedColumns.length} // Pass the correct totalColumns
    />
  ), [expandedRow, toggleRow, dateColumnStyle, displayedColumns]);

  const renderTableBody = () => {
    if (loadingTable) {
      return (
        <tr>
          <td colSpan={displayedColumns.length} className="text-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 mx-auto"></div>
            <p>Loading...</p>
          </td>
        </tr>
      );
    }

    console.log('Rendering table body with data:', sortedData);
    return sortedData.map((item, index) => {
     // console.log('Rendering item:', item);
      switch (item.type) {
        case "company":
          return renderCompanyRow(item, index);
        case "economic":
          return renderEconomicEventRow(item, index);
        default:
          console.log('Unknown item type:', item);
          return null;
      }
    });
  };

  // Sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor)
  );

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const activeIndex = displayedColumns.findIndex((col) => col.key === active.id);
      const overIndex = displayedColumns.findIndex((col) => col.key === over.id);

      const activeColumn = displayedColumns[activeIndex];
      const overColumn = displayedColumns[overIndex];

      if (activeColumn.isActionsColumn || overColumn.isActionsColumn) {
        // Do not allow moving the Actions column
        return;
      }

      const newColumns = [...displayedColumns];

      // Remove the item being dragged
      const [movedColumn] = newColumns.splice(activeIndex, 1);

      // Insert the item at the new position
      newColumns.splice(overIndex, 0, movedColumn);

      // Ensure Actions column stays after the Company column
      const companyIndex = newColumns.findIndex((col) => col.isCompanyColumn);
      const actionsIndex = newColumns.findIndex((col) => col.isActionsColumn);

      // Remove Actions column
      const [actionsColumn] = newColumns.splice(actionsIndex, 1);

      // Reinsert Actions column after the Company column
      newColumns.splice(companyIndex + 1, 0, actionsColumn);

      onColumnReorder(newColumns);
    }
  }, [displayedColumns, onColumnReorder]);

  // State to control ColumnSettingsModal visibility
  const [isColumnSettingsModalOpen, setIsColumnSettingsModalOpen] = useState(false);

  const openColumnSettingsModal = useCallback(() => {
    console.log('Settings icon clicked');
    setIsColumnSettingsModalOpen(true);
  }, []);

  const closeColumnSettingsModal = useCallback(() => {
    setIsColumnSettingsModalOpen(false);
  }, []);

  const handleSaveColumns = useCallback((selectedColumnKeys) => {
    // Filter out the notes column from user selection if it's there,
    // since we handle it separately by adding it dynamically
    const filteredColumnKeys = selectedColumnKeys.filter(key => key !== 'notes');
    
    // Filter the selected columns from ALL_COLUMNS to maintain consistent order
    const selectedColumns = ALL_COLUMNS.filter((col) =>
      filteredColumnKeys.includes(col.key)
    );

    // Find indexes of Company and Actions columns
    const companyIndex = selectedColumns.findIndex((col) => col.key === 'company');
    const actionsIndex = selectedColumns.findIndex((col) => col.key === 'actions');

    // Ensure Actions column is immediately after Company
    if (actionsIndex !== -1 && companyIndex !== -1 && actionsIndex !== companyIndex + 1) {
      // Remove Actions column from its current position
      const [actionsColumn] = selectedColumns.splice(actionsIndex, 1);
      // Insert Actions column immediately after Company column
      selectedColumns.splice(companyIndex + 1, 0, actionsColumn);
    }

    // Update columns
    onColumnReorder(selectedColumns);
  }, [onColumnReorder]);

  // Add console log to verify settings updates
  const handleSettingsChange = useCallback((category, value) => {
    console.log('StockTable handleSettingsChange:', { category, value });
    if (onSettingsChange) {
      onSettingsChange(category, value);
    }
  }, [onSettingsChange]);

  // Add debug log
  console.log('StockTable rendering with economicEvents:', economicEvents);

  return (
    <div className="overflow-auto">
      {/* Wrap ColumnSettingsModal with Modal component */}
      <Modal
        isOpen={isColumnSettingsModalOpen}
        onRequestClose={closeColumnSettingsModal}
        contentLabel="Column Settings"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ColumnSettingsModal
          columns={columns}
          onSave={handleSaveColumns}
          onClose={closeColumnSettingsModal}
        />
      </Modal>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <table className="min-w-full bg-white">
          <colgroup>
            {displayedColumns.map((column, index) => (
              <col key={`col-${column.key}-${index}`} />
            ))}
          </colgroup>
          <thead>
            <SortableContext
              items={displayedColumns
                .filter((col) => !col.isFixed && !col.isActionsColumn)
                .map((col) => col.key)}
              strategy={horizontalListSortingStrategy}
            >
              <tr className="bg-gray-100">
                {displayedColumns.map((column, index) => (
                  <SortableHeader
                    key={`header-${column.key}-${index}`}
                    column={column}
                    requestSort={requestSort}
                    sortConfig={sortConfig}
                    onSettingsClick={openColumnSettingsModal}
                    onColorSettingsClick={onColorSettingsClick}
                  />
                ))}
              </tr>
            </SortableContext>
          </thead>
          <tbody>
            <tr>
              <td colSpan={displayedColumns.length} className="p-0">
                <EconomicEventControls
                  userSettings={userSettings}
                  onSettingsChange={handleSettingsChange}
                  showEconomicEvents={showEconomicEvents}
                  setShowEconomicEvents={setShowEconomicEvents}
                  analysis={econAnalysis}
                />
              </td>
            </tr>

            {/*  Add a row for the listExplanation text, if present */}
            {listExplanation && (
              <tr className="explanation-row">
                <td colSpan={displayedColumns.length}>
                  {console.log('listExplanation:', listExplanation)}
                  {formatJsonContent(listExplanation)}
                </td>
              </tr>
            )}

            {renderTableBody()}
          </tbody>
        </table>
      </DndContext>
    </div>
  );
});
export default React.memo(StockTable);
