// config/config.js
const CONFIG = {
    CACHE_EXPIRY: {
      STOCK_PERFORMANCE: 3600000, // 1 hour
      ECONOMIC_DATA: 86400000, // 1 day
      TRAFFIC_DATA: 3600000, // 1 hour
      COMPANY_DATA: 382400000, // 1 week
      MEMORY_CACHE: 7200000, // 2 hours
      INDUSTRY_STATS: 3600000, // 1 hour
    },
    API_URLS: {
//      STOCK_PERFORMANCE: 'https://seeking-alpha.drisw.workers.dev/?ticker=',
//      COMPANY_PROFILE: 'https://company-profile.drisw.workers.dev/?tickers=',
//     WEB_APP_TRAFFIC: 'https://web-app-traffic-cached.drisw.workers.dev/fetchTraffic?domain=',
//      GLASSDOOR: 'https://api.1stprinciples.pro/glassdoor?query=',
      ECONOMIC_CALENDAR: 'https://api.1stprinciples.pro/economic-calendar',
      SCREENER: 'https://api.1stprinciples.pro/screener?list=',
      USER_DATA: 'https://api.1stprinciples.pro/api/user-data',
      EARNINGS_ANALYTICS: 'https://api.1stprinciples.pro',
      SCREEN_DATA: 'https://api.1stprinciples.pro/screen_query/screen-data',
      DAILY_MOVEMENT_ANALYTICS: 'https://api.1stprinciples.pro/daily-movement-analytics',
      SCREEN_QUERY: 'https://api.1stprinciples.pro/screen_query',
      NEWS: 'https://api.1stprinciples.pro/news',
      MARKET_NEWS: 'https://api.1stprinciples.pro/market-news',
      SECTOR_ROTATION: 'https://api.1stprinciples.pro/sector-rotation',
      AI_FILTER: 'https://api.1stprinciples.pro/ai-filter',
      USER_LISTS: 'https://api.1stprinciples.pro/lists',
      USER_LISTS_SHARE: 'https://api.1stprinciples.pro/lists/share',
      INDUSTRY_STATS: {
        BASE: 'https://api.1stprinciples.pro/industry-stats',
        LIST: 'https://api.1stprinciples.pro/industry-stats/list',
        DATA: 'https://api.1stprinciples.pro/industry-stats/data',
        COMPANIES: 'https://api.1stprinciples.pro/industry-stats/companies'
      },
      AUTH: {
        GENERATE_TOKEN: 'https://api.1stprinciples.pro/auth/generateToken',
        VALIDATE_TOKEN: 'https://api.1stprinciples.pro/auth/validateToken',
        INVALIDATE_TOKEN: 'https://api.1stprinciples.pro/auth/invalidateToken',
        REFRESH_TOKEN: 'https://api.1stprinciples.pro/auth/refreshToken'
      },
      MACRO_SCENARIOS: 'https://api.1stprinciples.pro/macro-scenarios',
      CURVES_DATA: 'https://api.1stprinciples.pro/curves-data',
      MARKET_OVERVIEW: 'https://api.1stprinciples.pro/market-overview',
    },
    API_PARAMS: {
      ECONOMIC_DATA: {
        COUNTRY_CODE: 'US',
        DAYS_BACK: 5,
        DAYS_FORWARD: 10,
      },
    },
    CERTIFICATES: {
      CLIENT_CERT: {
        CERT: process.env.CLIENT_CERT,
        KEY: process.env.CLIENT_KEY
      }
    },
  };
  
  // Initialize certificates if they're in the environment
  if (typeof process !== 'undefined' && process.env) {
    if (process.env.CLIENT_CERT) {
      CONFIG.CERTIFICATES.CLIENT_CERT.CERT = process.env.CLIENT_CERT;
    }
    if (process.env.CLIENT_KEY) {
      CONFIG.CERTIFICATES.CLIENT_CERT.KEY = process.env.CLIENT_KEY;
    }
  }
  
  export default CONFIG;
  