// services/api.js
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import CONFIG from "../config/config";
import { authenticatedFetch, publicFetch } from "../utils/authenticatedFetch";
import { loadSettings, saveSettings } from '../utils/settings';
import { useState, useEffect } from 'react';

// Helper functions
const isValidDate = (date) => !isNaN(Date.parse(date));

const estimateNextEarningsDate = (lastEarningsDate) => {
  if (!lastEarningsDate) return null;
  const lastDate = new Date(lastEarningsDate);
  return new Date(lastDate.setMonth(lastDate.getMonth() + 3))
    .toISOString()
    .split("T")[0];
};

// Consolidated screen data fetch
export const fetchScreenData = async (screen, userEmail, customSymbols = null, filters = {}) => {
  let url = `${CONFIG.API_URLS.SCREEN_DATA}`;
  const params = new URLSearchParams();

  if (customSymbols) {
    if (Array.isArray(customSymbols) && customSymbols.length > 0) {
      params.append('symbols', customSymbols.join(','));
    } else {
      console.warn('Invalid or empty customSymbols:', customSymbols);
      return [];
    }
  } else if (screen) {
    params.append('screen', screen);
    if (screen === "watchlist" && userEmail) {
      params.append('userEmail', userEmail);
    }
  } else {
    throw new Error('Either screen or symbols parameter is required');
  }

  // Add market cap filter parameters if present
  if (filters.marketCap && filters.marketCap.length > 0) {
    // Map market cap tier IDs to min/max values from MARKET_CAP_TIERS
    const marketCapRanges = filters.marketCap.map(tierId => {
      // Import the tiers from MarketCapFilter to get the actual values
      const { MARKET_CAP_TIERS } = require('../components/MarketCapFilter');
      const tier = MARKET_CAP_TIERS.find(t => t.id === tierId);
      return tier ? { min: tier.min, max: tier.max } : null;
    }).filter(Boolean);

    // Find the overall min and max
    if (marketCapRanges.length > 0) {
      const minMarketCap = Math.min(...marketCapRanges.map(r => r.min !== null ? r.min : Infinity));
      const maxMarketCap = Math.max(...marketCapRanges.map(r => r.max !== Infinity ? r.max : 0));
      
      if (minMarketCap !== Infinity) params.append('minMarketCap', minMarketCap);
      if (maxMarketCap !== 0) params.append('maxMarketCap', maxMarketCap);
    }
  }

  // Add industries filter if present
  if (filters.industries && filters.industries.length > 0) {
    // Use the industry names directly as they come from the API
    // The IDs in the filter are already the exact industry names
    params.append('industries', filters.industries.join(','));
  }

  url += `?${params.toString()}`;
  
  // Decide whether to use publicFetch or authenticatedFetch
  // For example, only use authenticatedFetch for watchlist if userEmail is present.
  let fetchFn = publicFetch;
  if (screen === "watchlist" && userEmail) {
    fetchFn = authenticatedFetch;
  }

  try {
    const response = await fetchFn(url);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const screenData = await response.json();
    console.log("Raw API response:", screenData);
    console.log("Raw screen data:", screenData);

    if (!screenData || !screenData.stocks || screenData.stocks.length === 0) {
      console.error("No stocks data found in the API response");
      return [];
    }

    // Process the data
    const processedData = screenData.stocks.map((stock) => {
      let categories = [];
      try {
        if (typeof stock.category_id === 'string') {
          // If it's a JSON string, parse it
          categories = JSON.parse(stock.category_id);
        } else if (Array.isArray(stock.category_id)) {
          // Already an array
          categories = stock.category_id;
        } else if (stock.category_id) {
          // Fallback for any other non-null value
          categories = [stock.category_id];
        }
      } catch (e) {
        console.error('Error processing categories for', stock.symbol, e);
        categories = stock.category_id ? [stock.category_id] : [];
      }

    //  console.log('Processed categories for', stock.symbol, {
      //  rawCategory: stock.category_id,
        //processedCategories: categories
      //});

      // Deduplicate signals by name, keeping the strongest one
      const uniqueSignals = stock.technical_signals?.reduce((acc, signal) => {
        const existingSignal = acc.find(s => s.name === signal.name);
        if (!existingSignal || existingSignal.strength < signal.strength) {
          // Remove existing signal if any
          const filtered = acc.filter(s => s.name !== signal.name);
          // Add the new (or stronger) signal
          return [...filtered, signal];
        }
        return acc;
      }, []) || [];

      return {
        name: stock.companyName,
        tickerSymbol: stock.symbol,
        website: stock.website,
        fullTimeEmployees: stock.fullTimeEmployees,
        industry: stock.industry,
        overallRisk: stock.overallRisk,
        description: stock.description,
        earningsDate: stock.next_earnings_date,
        isEarningsDateEstimated: stock.next_earnings_date_estimated === 1,
        stockPrice: stock.current_price != null && !isNaN(stock.current_price)
          ? parseFloat(stock.current_price)
          : null,
        stockChange: stock.percent_change ? parseFloat(stock.percent_change) : null,
        positions: stock.glassdoor_job_count?.toString() || "N/A",
        positionsPercentage: stock.glassdoor_job_count && stock.fullTimeEmployees
          ? (stock.glassdoor_job_count / stock.fullTimeEmployees) * 100
          : null,
        outlook: stock.glassdoor_business_outlook_rating?.toString() || "N/A",
        logo_url: stock.logo_url || null,
        trafficData: stock.trafficData || [],
        trafficChange: calculateTrafficChange(stock.trafficData),
        currentTraffic: stock.trafficData?.[0]?.visits || null,
        lastEarningsDate: stock.last_earnings_date,
        ai_analysis: stock.ai_analysis || null,
        // New fields from current_price_cache
        regularMarketChange: stock.regular_market_change,
        regularMarketChangePercent: stock.regular_market_change_percent != null
          ? parseFloat(stock.regular_market_change_percent)
          : null,
        regularMarketTime: stock.regular_market_time,
        regularMarketDayHigh: stock.regular_market_day_high,
        regularMarketDayLow: stock.regular_market_day_low,
        regularMarketVolume: stock.regular_market_volume,
        regularMarketPreviousClose: stock.regular_market_previous_close,
        preMarketPrice: stock.pre_market_price,
        preMarketChange: stock.pre_market_change,
        preMarketChangePercent: stock.pre_market_change_percent,
        postMarketPrice: stock.post_market_price,
        postMarketChange: stock.post_market_change,
        postMarketChangePercent: stock.post_market_change_percent,
        fiftyTwoWeekLow: stock.fifty_two_week_low,
        fiftyTwoWeekHigh: stock.fifty_two_week_high,
        fiftyTwoWeekChangePercent: stock.fifty_two_week_change_percent,
        averageVolume3Month: stock.average_volume_3month,
        averageVolume10Day: stock.average_volume_10day,
        fiftyDayAverage: stock.fifty_day_average,
        fiftyDayAverageChange: stock.fifty_day_average_change,
        fiftyDayAverageChangePercent: stock.fifty_day_average_change_percent,
        twoHundredDayAverage: stock.two_hundred_day_average,
        twoHundredDayAverageChange: stock.two_hundred_day_average_change,
        twoHundredDayAverageChangePercent: stock.two_hundred_day_average_change_percent,
        fiftyTwoWeekLowChange: stock.fifty_two_week_low_change,
        fiftyTwoWeekLowChangePercent: stock.fifty_two_week_low_change_percent,
        fiftyTwoWeekHighChange: stock.fifty_two_week_high_change,
        fiftyTwoWeekHighChangePercent: stock.fifty_two_week_high_change_percent,
        // Fields from stock_financial_info
        currency: stock.currency,
        quoteType: stock.quote_type,
        shortName: stock.short_name,
        longName: stock.long_name,
        marketCap: stock.market_cap,
        bookValue: stock.book_value,
        sharesOutstanding: stock.shares_outstanding,
        averageAnalystRating: stock.average_analyst_rating,
        forwardPE: stock.forward_pe,
        trailingPE: stock.trailing_pe,
        priceToBook: stock.price_to_book,
        trailingAnnualDividendRate: stock.trailing_annual_dividend_rate,
        trailingAnnualDividendYield: stock.trailing_annual_dividend_yield,
        epsTrailingTwelveMonths: stock.eps_trailing_twelve_months,
        epsForward: stock.eps_forward,
        trailingPegRatio: stock.trailing_peg_ratio,
        dividendRate: stock.dividend_rate,
        dividendYield: stock.dividend_yield,
        financialInfoLastUpdated: stock.financial_info_last_updated,
        // Financial Stats fields
        financialStatsLastUpdated: stock.financial_stats_last_updated,
        shortPercentOfFloat: stock.short_percent_of_float != null ? parseFloat(stock.short_percent_of_float) : null,
        enterpriseToRevenue: stock.enterprise_to_revenue != null ? parseFloat(stock.enterprise_to_revenue) : null,
        enterpriseToEbitda: stock.enterprise_to_ebitda != null ? parseFloat(stock.enterprise_to_ebitda) : null,
        pegRatio: stock.peg_ratio != null ? parseFloat(stock.peg_ratio) : null,
        quickRatio: stock.quick_ratio != null ? parseFloat(stock.quick_ratio) : null,
        currentRatio: stock.current_ratio != null ? parseFloat(stock.current_ratio) : null,
        grossMargins: stock.gross_margins != null ? parseFloat(stock.gross_margins) : null,
        operatingMargins: stock.operating_margins != null ? parseFloat(stock.operating_margins) : null,
        totalCashPerShare: stock.total_cash_per_share != null ? parseFloat(stock.total_cash_per_share) : null,
        beta: stock.beta != null ? parseFloat(stock.beta) : null,
        priceToSalesTrailing12Months: stock.price_to_sales_trailing_12_months != null ? 
          parseFloat(stock.price_to_sales_trailing_12_months) : null,
        trailingEps: stock.trailing_eps != null ? parseFloat(stock.trailing_eps) : null,
        forwardEps: stock.forward_eps != null ? parseFloat(stock.forward_eps) : null,
        
        // Also keep the original field names for compatibility
        enterprise_to_revenue: stock.enterprise_to_revenue,
        enterprise_to_ebitda: stock.enterprise_to_ebitda,
        quick_ratio: stock.quick_ratio,
        current_ratio: stock.current_ratio,
        total_cash_per_share: stock.total_cash_per_share,
        price_to_sales_trailing_12_months: stock.price_to_sales_trailing_12_months,
        trailing_eps: stock.trailing_eps,
        forward_eps: stock.forward_eps,
        
        // Add technical signals processing before the debug section
        technicalSignals: uniqueSignals,
        
        // Computed properties for quick access
        latestSignal: stock.technical_signals?.[0] || null,
        hasBuySignals: stock.technical_signals?.some(s => s.type.toUpperCase() === 'BUY') || false,
        hasSellSignals: stock.technical_signals?.some(s => s.type.toUpperCase() === 'SELL') || false,
        
        // Keep the original field names from the API
        technical_signals: stock.technical_signals || [],
        
        // Add debug logging
        ...(process.env.NODE_ENV === 'development' && {
          _debug_margins: {
            gross: stock.gross_margins,
            operating: stock.operating_margins
          }
        }),
        
        // Update category handling to support arrays
        categories,
        category_id: categories[0] || null, // For backward compatibility, use first category
        
        // Add these fields
        revenueGrowth: stock.revenue_growth != null ? parseFloat(stock.revenue_growth) : null,
        revenue_growth: stock.revenue_growth, // Keep original field name for compatibility
      };
    });

    console.log(`Processed screen data for ${screen || 'custom symbols'}:`, processedData);
    return processedData;
  } catch (error) {
    console.error(`Error fetching screen data for ${screen || 'custom symbols'}:`, error);
    throw error;
  }
};

const calculateTrafficChange = (trafficData) => {
  if (!trafficData || trafficData.length < 2) return null;
  const sortedData = [...trafficData].sort((a, b) => new Date(b.trafficDate) - new Date(a.trafficDate));
  const currentTraffic = sortedData[0].visits;
  const previousTraffic = sortedData[sortedData.length - 1].visits;
  return ((currentTraffic - previousTraffic) / previousTraffic) * 100;
};

export const useWatchlistData = (email) => {
  return useQuery({
    queryKey: ["watchlistData", email],
    queryFn: async () => {
      if (!email) return [];
      try {
        // First, fetch user's watchlist symbols
        const userResponse = await authenticatedFetch(
          `${CONFIG.API_URLS.USER_DATA}?userId=${email}`
        );

        if (!userResponse.ok) {
          throw new Error(`HTTP error! status: ${userResponse.status}`);
        }

        const userData = await userResponse.json();
        console.log('Raw user data:', userData);

        // Get server symbols
        const serverSymbols = userData?.companiesSymbols || [];
        
        // Get local symbols
        const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
        
        // Merge symbols
        const mergedSymbols = Array.from(new Set([...serverSymbols, ...localSymbols]));

        if (!mergedSymbols.length) {
          console.log('No watchlist symbols found');
          return {
            companiesSymbols: [],
            companies: [],
            preferences: userData.preferences || {}
          };
        }

        // Fetch stock data for merged symbols
        const stockData = await fetchScreenData('watchlist', email, mergedSymbols);
        console.log('Fetched stock data for watchlist:', stockData);

        return {
          companiesSymbols: mergedSymbols,
          companies: stockData,
          preferences: userData.preferences || {}
        };
      } catch (error) {
        console.error('Error fetching watchlist data:', error);
        return {
          companiesSymbols: [],
          companies: [],
          preferences: {}
        };
      }
    },
    enabled: !!email,
    retry: 3,
    retryDelay: 1000,
  });
};

export const updateWatchlist = async (user, symbols) => {
  try {
    if (!user?.email) {
      throw new Error('User email is required');
    }

    if (!Array.isArray(symbols)) {
      throw new Error('Symbols must be an array');
    }

    console.log('Updating watchlist:', {
      email: user.email,
      symbolsCount: symbols.length
    });

    const response = await authenticatedFetch(CONFIG.API_URLS.USER_DATA, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: user.email,
        email: user.email,
        companiesSymbols: symbols,
        firstUseTimestamp: localStorage.getItem('firstUseTimestamp') || new Date().toISOString()
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Watchlist update failed:', errorText);
      throw new Error(`HTTP error! status: ${response.status} - ${errorText}`);
    }

    const data = await response.json();
    console.log('Watchlist update response:', data);
    return data;
  } catch (error) {
    console.error('Error updating watchlist:', error);
    throw error;
  }
};

export const useEconomicData = (geography) => {
  return useQuery({
    queryKey: ["economicData", geography],
    queryFn: async () => {
      const url = `${CONFIG.API_URLS.ECONOMIC_CALENDAR}?timeframe=-1,0,1&geography=${geography}`;
      console.log("Fetching economic data from:", url);
      try {
        const response = await publicFetch(url);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log("Economic data response:", data); // Debug log

        if (!data || typeof data !== "object") {
          throw new Error("Invalid economic data received");
        }

        // Ensure we return the expected structure
        return {
          events: data.events || {}, // Events should be an object with dates as keys
          analysis: data.analysis || null // Analysis should be the analysis object or null
        };
      } catch (error) {
        console.error(`Error fetching economic data:`, error.message);
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep unused data in cache for 30 minutes
    refetchOnWindowFocus: false,
    retry: 1,
  });
};

export const useMarketAnalysis = (forceRefresh = false) => {
  return useQuery({
    queryKey: ['marketAnalysis', forceRefresh],
    queryFn: async () => {
      const today = new Date().toISOString().split('T')[0];
      const response = await authenticatedFetch(
        `${CONFIG.API_URLS.MARKET_NEWS}?date=${today}&forceRefresh=${forceRefresh}`
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Raw Market Analysis API Response:', data);
      return data;
    },
    refetchInterval: 60 * 60 * 1000, // Refetch every hour
    staleTime: 60 * 60 * 1000, // Consider data fresh for 1 hour
    cacheTime: 2 * 60 * 60 * 1000, // Keep unused data in cache for 2 hours
  });
};

const fetchEconomicData = async (geography = "US") => {
  const url = `${CONFIG.API_URLS.ECONOMIC_CALENDAR}?timeframe=-1,0,1&geography=${geography}`;
  console.log("Fetching economic data from:", url);
  try {
    const response = await axios.get(url);
    if (!response.data || typeof response.data !== "object") {
      throw new Error("Invalid economic data received");
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching economic data:`, error.message);
    throw error;
  }
};

export const updateUserData = async (user, data) => {
  try {
    // Ensure we have either id or email
    const userId = user.id || user.email;
    const emailString = typeof user.email === 'object' ? user.email.email : user.email;

    // Remove lists from preferences before sending
    const { lists, ...otherPreferences } = (data.preferences || {});

    const payload = {
      email: emailString,
      preferences: otherPreferences, // Send preferences without lists
      companiesSymbols: Array.isArray(data.companiesSymbols) ? 
        JSON.stringify(data.companiesSymbols) : 
        data.companiesSymbols || '[]',
      name: data.name || null,
      firstUseTimestamp: data.firstUseTimestamp || null
    };

    console.log('Sending user data payload:', payload);

    const response = await authenticatedFetch(CONFIG.API_URLS.USER_DATA, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Server error response:', errorData);
      throw new Error(errorData.error || 'Failed to update user data');
    }

    const result = await response.json();
    if (result.id) {
      localStorage.setItem('userId', result.id);
    }
    return result;
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

// Improved createUserList combining best of both versions
export const createUserList = async (listData) => {
  try {
    console.log('Creating list with data:', listData);
    
    // Format data with proper validation
    const formattedData = {
      ...listData,
      symbols: Array.isArray(listData.symbols) ? 
        JSON.stringify(listData.symbols) : 
        listData.symbols,
      is_public: listData.is_public || false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };

    const response = await authenticatedFetch(CONFIG.API_URLS.USER_LISTS, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formattedData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to create list');
    }

    const savedList = await response.json();
    
    // Return parsed data
    return {
      ...savedList,
      symbols: typeof savedList.symbols === 'string' ? 
        JSON.parse(savedList.symbols) : 
        savedList.symbols
    };
  } catch (error) {
    console.error('Error creating list:', error);
    throw error;
  }
};

export const shareUserList = async (listId, userId, accessType = 'view') => {
  try {
    const response = await authenticatedFetch(`${CONFIG.API_URLS.USER_LISTS}/${listId}/share`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, accessType })
    });

    if (!response.ok) {
      throw new Error('Failed to share list');
    }

    return await response.json();
  } catch (error) {
    console.error('Error sharing list:', error);
    throw error;
  }
};

export const useCategories = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      try {
        const response = await publicFetch(`${CONFIG.API_URLS.SCREEN_QUERY}/available-screens`);

        if (!response.ok) {
          console.error('Response not ok. Status:', response.status, response.statusText);
          const text = await response.text();
          console.error('Body content:', text);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const categories = await response.json();

        // 1) Post-process each category to unify short_description with description
        categories.forEach(category => {
          if (category.short_description) {
            // Overwrite description with short_description if it exists
            category.description = category.short_description;
          }

          // 2) Do the same for each filter
          if (category.filters) {
            category.filters.forEach(filter => {
              if (filter.short_description) {
                filter.description = filter.short_description;
              }
            });
          }
        });

        // Process the response to include parent-child relationships
        categories.forEach(category => {
          if (category.filters) {
            category.filters = category.filters.map(filter => ({
              ...filter,
              children: category.filters.filter(f => f.parent_list === filter.id)
            }));

            // Keep only top-level filters in the main array
            category.filters = category.filters.filter(filter => !filter.parent_list);
          }
        });

        return categories;
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

export const useAIFilter = () => {
  return useMutation({
    mutationFn: async (prompt) => {
      const response = await publicFetch('https://api.1stprinciples.pro/ai-filter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });

      if (!response.ok) {
        throw new Error('Failed to process AI filter request');
      }

      return response.json();
    },
  });
};

export const saveScreenerData = async (screenerData) => {
  const response = await fetch('https://api.1stprinciples.pro/screen', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(screenerData),
  });

  if (!response.ok) {
    throw new Error('Failed to save screener data');
  }

  return response.json();
};

// Add new list management functions
export const updateUserList = async (listId, listData) => {
  try {
    const response = await authenticatedFetch(`${CONFIG.API_URLS.USER_LISTS}/${listId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(listData)
    });

    if (!response.ok) {
      throw new Error('Failed to update list');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating list:', error);
    throw error;
  }
};

// Improved useUserLists combining best of both versions
export const useUserLists = (email) => {
  const queryClient = useQueryClient();

  const { data: userLists, ...rest } = useQuery({
    queryKey: ['userLists', email],
    queryFn: async () => {
      if (!email) return [];
      
      try {
        const response = await authenticatedFetch(CONFIG.API_URLS.USER_LISTS);
        if (!response.ok) throw new Error('Failed to fetch user lists');
        
        const lists = await response.json();
        
        // Parse JSON fields for all lists
        const processedLists = lists.map(list => ({
          ...list,
          symbols: typeof list.symbols === 'string' ? JSON.parse(list.symbols) : list.symbols,
          ...(list.type === 'portfolio' && {
            sector_analysis: list.sector_analysis ? JSON.parse(list.sector_analysis) : null,
            risk_metrics: list.risk_metrics ? JSON.parse(list.risk_metrics) : null,
            suggested_symbols: list.suggested_symbols ? JSON.parse(list.suggested_symbols) : null
          })
        }));

        // Update local settings
        saveSettings('lists', processedLists);
        return processedLists;
      } catch (error) {
        console.error('Error fetching user lists:', error);
        // Fallback to local settings on error
        return loadSettings().lists;
      }
    },
    enabled: !!email,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
    initialData: () => loadSettings().lists,
    retry: 3,
    retryDelay: 1000
  });

  // Mutation for creating lists
  const createList = useMutation({
    mutationFn: async (listData) => {
      const response = await authenticatedFetch(CONFIG.API_URLS.USER_LISTS, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(listData)
      });

      if (!response.ok) {
        throw new Error('Failed to create list');
      }

      return response.json();
    },
    onSuccess: (newList) => {
      queryClient.invalidateQueries({ queryKey: ['userLists', email] });
      const currentSettings = loadSettings();
      saveSettings('lists', [...currentSettings.lists, newList]);
    }
  });

  // Mutation for deleting lists
  const deleteList = useMutation({
    mutationFn: async (listId) => {
      const response = await authenticatedFetch(`${CONFIG.API_URLS.USER_LISTS}/${listId}`, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('Failed to delete list');
      }

      return response.json();
    },
    onSuccess: (_, deletedListId) => {
      queryClient.invalidateQueries({ queryKey: ['userLists', email] });
      const currentSettings = loadSettings();
      saveSettings('lists', currentSettings.lists.filter(list => list.id !== deletedListId));
    }
  });

  return {
    data: userLists,
    createList,
    deleteList,
    ...rest
  };
};

// Add delete list function
export const deleteUserList = async (listId) => {
  try {
    const response = await authenticatedFetch(`${CONFIG.API_URLS.USER_LISTS}/${listId}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new Error('Failed to delete list');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting list:', error);
    throw error;
  }
};

// Fetch industry list from the API
export const useIndustryList = () => {
  const [industries, setIndustries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(CONFIG.API_URLS.INDUSTRY_STATS.LIST);
        
        if (!response.ok) {
          throw new Error(`Failed to fetch industries: ${response.status}`);
        }
        
        const data = await response.json();
        
        // Transform the data into the format needed for the filter
        // Each industry has: industry, company_count, avg_market_cap, avg_pe_ratio, avg_revenue_growth, performance
        const formattedIndustries = data
          .filter(industry => industry.industry !== 'N/A' && industry.company_count > 0)
          .map(industry => ({
            id: industry.industry, // Use the exact industry name as the ID for matching in the database
            label: industry.industry,
            count: industry.company_count,
            marketCap: industry.avg_market_cap,
            peRatio: industry.avg_pe_ratio,
            revenueGrowth: industry.avg_revenue_growth,
            performance: industry.performance
          }));
        
        // Sort by company count (descending)
        formattedIndustries.sort((a, b) => b.count - a.count);
        
        setIndustries(formattedIndustries);
        setError(null);
      } catch (err) {
        console.error('Error fetching industry list:', err);
        setError(err.message);
        setIndustries([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchIndustries();
  }, []);

  return { industries, isLoading, error };
};
