import React from 'react';
import { FiAlertTriangle, FiActivity, FiTrendingUp, FiTarget, FiCalendar, FiGlobe } from 'react-icons/fi';

function MarketOverviewSection({ data, isLoading, error }) {
  if (isLoading) return <div className="p-3 text-gray-500">Loading Market Overview...</div>;
  if (error) {
    return (
      <div className="p-3 text-red-500 text-sm">
        <FiAlertTriangle className="inline mr-2" />
        Unable to load Market Overview: {error.message || 'Unknown error'}
      </div>
    );
  }
  if (!data?.overview) return null;

  const {
    date,
    opening_overview,
    key_news_and_market_reactions,
    stock_moves_and_sector_highlights,
    upcoming_earnings_and_economic_events,
    global_considerations,
    investment_recommendations,
    closing_summary
  } = data.overview;

  // Helper to render news items
  const renderNewsItems = (newsItems) => {
    if (!newsItems?.length) return null;
    return newsItems.map((item, idx) => (
      <div key={idx} className="news-item">
        <h5>{item.headline}</h5>
        <p className="details">{item.details}</p>
        <p className="impact">{item.impact}</p>
      </div>
    ));
  };

  // Helper to render sector movements
  const renderSectorMoves = (sectors) => {
    if (!sectors?.length) return null;
    return sectors.map((sector, idx) => (
      <div key={idx} className="sector-move">
        <h4>{sector.sector}</h4>
        <div className="sector-table-container">
          <table className="sector-table">
            <thead>
              <tr>
                <th>Company</th>
                <th>Ticker</th>
                <th>Price</th>
                <th>Movement</th>
              </tr>
            </thead>
            <tbody>
              {sector.stocks.map((stock) => (
                <tr key={stock.ticker}>
                  <td>{stock.company}</td>
                  <td>{stock.ticker}</td>
                  <td>{stock.current_price.toFixed(2)}</td>
                  <td>{stock.movement_description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="sector-commentary">{sector.sector_commentary}</p>
      </div>
    ));
  };

  // Helper to render upcoming events
  const renderUpcomingEvents = (events) => {
    if (!events) return null;
    return (
      <div className="events-container">
        <div>
          <h4>Upcoming Earnings</h4>
          {events.upcoming_earnings?.map((event, idx) => (
            <div key={idx} className="event-item">
              <p className="title">{event.company} - {event.report_date}</p>
              <p className="description">{event.expected_impact}</p>
            </div>
          ))}
        </div>
        <div>
          <h4>Economic Events</h4>
          {events.upcoming_economic_events?.map((event, idx) => (
            <div key={idx} className="event-item">
              <p className="title">{event.event} - {event.date}</p>
              <p className="description">{event.details}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="market-overview-container">
      <div className="market-header">
        <FiActivity className="icon" />
        <h2>Market Overview</h2>
      </div>
      
      {date && <div className="market-overview-date">As of {date}</div>}

      {opening_overview && (
        <div className="market-overview-section">
          <p>{opening_overview}</p>
        </div>
      )}

      {key_news_and_market_reactions && (
        <div className="market-overview-section">
          <h3>
            <FiAlertTriangle className="icon icon-alert" />
            Key News & Market Reactions
          </h3>
          {renderNewsItems(key_news_and_market_reactions)}
        </div>
      )}

      {stock_moves_and_sector_highlights && (
        <div className="market-overview-section">
          <h3>
            <FiTrendingUp className="icon icon-trending" />
            Stock Moves & Sector Highlights
          </h3>
          {renderSectorMoves(stock_moves_and_sector_highlights)}
        </div>
      )}

      {upcoming_earnings_and_economic_events && (
        <div className="market-overview-section">
          <h3>
            <FiCalendar className="icon icon-calendar" />
            Upcoming Events
          </h3>
          {renderUpcomingEvents(upcoming_earnings_and_economic_events)}
        </div>
      )}

      {global_considerations && (
        <div className="market-overview-section">
          <h3>
            <FiGlobe className="icon icon-globe" />
            Global Considerations
          </h3>
          {global_considerations.map((item, idx) => (
            <div key={idx} className="global-item">
              <h4>{item.region}</h4>
              <p>{item.insights}</p>
            </div>
          ))}
        </div>
      )}

      {investment_recommendations && (
        <div className="market-overview-section">
          <h3>
            <FiTarget className="icon icon-target" />
            Investment Recommendations
          </h3>
          {investment_recommendations.map((rec, idx) => (
            <div key={idx} className="recommendation-item">
              <h4>{rec.strategy}</h4>
              <p>{rec.details}</p>
            </div>
          ))}
        </div>
      )}

      {closing_summary && (
        <div className="closing-summary">
          <h3>Closing Summary</h3>
          <p>{closing_summary}</p>
        </div>
      )}
    </div>
  );
}

export default MarketOverviewSection; 