import React, { useState } from 'react';
import { ALL_COLUMNS } from '../config/columnConfig';
import './ColumnSettingsModal.css';

const ColumnSettingsModal = ({ columns, onSave, onClose }) => {
  const fixedColumns = ALL_COLUMNS.filter((col) => col.isFixed);

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );

  const MAX_SELECTABLE_COLUMNS = 5;

  // State to handle the error message
  const [errorMessage, setErrorMessage] = useState('');

  const handleColumnToggle = (columnKey) => {
    setSelectedColumns((prev) => {
      const isFixed = fixedColumns.some((col) => col.key === columnKey);

      if (prev.includes(columnKey)) {
        // Prevent deselecting fixed columns
        if (isFixed) {
          return prev;
        }
        // Deselect the column
        return prev.filter((key) => key !== columnKey);
      } else {
        // Count non-fixed selected columns
        const nonFixedSelectedCount = prev.filter(
          (key) => !fixedColumns.some((col) => col.key === key)
        ).length;

        // Check if the maximum limit is reached
        if (nonFixedSelectedCount >= MAX_SELECTABLE_COLUMNS) {
          // Display a warning message
          setErrorMessage(`You can select up to ${MAX_SELECTABLE_COLUMNS} additional columns.`);
          return prev;
        }

        // Clear any existing error message
        if (errorMessage) setErrorMessage('');

        // Select the column
        return [...prev, columnKey];
      }
    });
  };

  const handleSave = () => {
    // Don't include the notes column in the saved configuration
    // since it will be added dynamically in the watchlist tab
    const filteredColumns = selectedColumns.filter(key => key !== 'notes');
    onSave(filteredColumns);
    onClose();
  };

  // Calculate the count of non-fixed selected columns
  const nonFixedSelectedCount = selectedColumns.filter(
    (key) => !fixedColumns.some((col) => col.key === key)
  ).length;

  return (
    <div className="column-settings-modal">
      <div className="modal-header">
        <h2>Customize Columns</h2>
        <button className="close-button" onClick={onClose} aria-label="Close modal">&times;</button>
      </div>
      <div className="modal-content">
        <p className="modal-instructions">
          Select up to {MAX_SELECTABLE_COLUMNS} additional columns to display in the table.
        </p>

        <div className="columns-grid">
          {ALL_COLUMNS.filter((col) => 
            // Exclude hidden columns and the Notes column from configuration
            !col.isHidden && !col.watchlistOnly
          ).map((col) => {
            const isFixed = fixedColumns.some((fixedCol) => fixedCol.key === col.key);
            const isSelected = selectedColumns.includes(col.key);

            // Disable checkbox if max limit is reached
            const disableCheckbox =
              isFixed ||
              (!isSelected && nonFixedSelectedCount >= MAX_SELECTABLE_COLUMNS);

            return (
              <div key={col.key} className={`column-item ${isSelected ? 'selected' : ''}`}>
                <label className={isFixed ? 'fixed-column' : ''}>
                  <input
                    type="checkbox"
                    checked={isSelected}
                    disabled={disableCheckbox}
                    onChange={() => handleColumnToggle(col.key)}
                  />
                  {col.label}
                  {isFixed && <span className="fixed-tag">Required</span>}
                </label>
              </div>
            );
          })}
        </div>
        
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        
        <div className="info-message">
          <small>Note: When viewing your watchlist, a Notes column will automatically be added.</small>
        </div>
      </div>
      
      <div className="modal-actions">
        <div className="action-left">
          <p className="column-counter">
            Selected: {nonFixedSelectedCount} / {MAX_SELECTABLE_COLUMNS}
          </p>
        </div>
        <div className="action-right">
          <button className="secondary-button" onClick={onClose}>Cancel</button>
          <button className="primary-button" onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default ColumnSettingsModal;
