import React, { useState, useEffect, useMemo } from 'react';
import '../styles/TradingRangeInsights.css';

const TradingRangeInsights = ({ stock }) => {
  // Extract necessary data from stock
  const {
    stockPrice, // Current price (string)
    fiftyTwoWeekLow,
    fiftyTwoWeekHigh,
    fiftyDayAverage,
    twoHundredDayAverage,
    regularMarketVolume,
    averageVolume3Month,
    averageVolume10Day,
  } = stock;

  // Convert string values to numbers
  const currentPrice = parseFloat(stockPrice);
  const priceMin = parseFloat(fiftyTwoWeekLow);
  const priceMax = parseFloat(fiftyTwoWeekHigh);
  const fiftyDayAvg = parseFloat(fiftyDayAverage);
  const twoHundredDayAvg = parseFloat(twoHundredDayAverage);
  const volumeCurrent = parseFloat(regularMarketVolume);
  const volumeAvg3M = parseFloat(averageVolume3Month);
  const volumeAvg10D = parseFloat(averageVolume10Day);

  // Adjust price range to add a buffer to the left of the minimum value
  const adjustedPriceMin = priceMin - (priceMax - priceMin) * 0.05;
  const adjustedPriceMax = priceMax;

  // Adjust volume range to start at the lowest value instead of 0
  const volumeMin = Math.min(volumeCurrent, volumeAvg3M, volumeAvg10D);
  const adjustedVolumeMin = !isNaN(volumeMin) && volumeMin > 0 ? volumeMin * 0.95 : 1;

  // Validate data
  const isPriceDataValid =
    !isNaN(priceMin) && !isNaN(priceMax) && !isNaN(currentPrice);
  const isVolumeDataValid =
    !isNaN(volumeCurrent) &&
    (!isNaN(volumeAvg3M) || !isNaN(volumeAvg10D));

  // Hooks must be called before any returns
  const [priceMarkerPositions, setPriceMarkerPositions] = useState([]);
  const [volumeMarkerPositions, setVolumeMarkerPositions] = useState([]);
  const [interpretation, setInterpretation] = useState('');
  const [badgeType, setBadgeType] = useState('');

  // Styles used in useEffect
  const currentMarkerStyle = useMemo(
    () => ({
      width: '20px',
      height: '20px',
      backgroundColor: '#3b82f6', // blue-500
      borderRadius: '50%',
    }),
    []
  );

  const averageMarkerStyle = useMemo(
    () => ({
      width: '20px',
      height: '20px',
      backgroundColor: '#10b981', // green-500
      clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
    }),
    []
  );

  // Helper function to calculate position percentage
  const calculateAdjustedPosition = (value, min, max) => {
    if (max === min) return 0;
    const normalizedValue = (value - min) / (max - min);
    return normalizedValue * 100;
  };

  // For Price range
  const priceAverages = useMemo(
    () =>
      [
        { label: '50D Avg', value: fiftyDayAvg },
        { label: '200D Avg', value: twoHundredDayAvg },
      ].filter((avg) => !isNaN(avg.value)),
    [fiftyDayAvg, twoHundredDayAvg]
  );

  // For Volume range
  const volumeAverages = useMemo(
    () =>
      [
        { label: '3M Avg', value: volumeAvg3M },
        { label: '10D Avg', value: volumeAvg10D },
      ].filter((avg) => !isNaN(avg.value)),
    [volumeAvg3M, volumeAvg10D]
  );

  const volumeMax = Math.max(
    volumeCurrent || 0,
    ...volumeAverages.map((avg) => avg.value)
  );

  const calculateMarkerPositions = (markers) => {
    const sortedMarkers = markers.sort((a, b) => a.position - b.position);
    const minSpacing = 5; // Minimum horizontal spacing in percentage
    let lastPosition = -Infinity;
    let verticalOffset = 0;

    return sortedMarkers.map((marker) => {
      if (marker.position - lastPosition < minSpacing) {
        verticalOffset = (verticalOffset + 1) % 3; // Cycle through 3 vertical positions
      } else {
        verticalOffset = 0;
      }
      lastPosition = marker.position;
      return { ...marker, verticalOffset };
    });
  };

  // Helper functions for analysis
  const analyzePriceAndVolume = (currentPrice, fiftyDayAvg, twoHundredDayAvg, currentVolume, avgVolume10D, avgVolume3M, technicalSignals) => {
    const priceChange = currentPrice - fiftyDayAvg;
    const shortTermVsLongTermPrice = fiftyDayAvg / twoHundredDayAvg;
    const shortTermVsLongTermVolume = avgVolume10D / avgVolume3M;
    const currentVsShortTermVolume = currentVolume / avgVolume10D;
    const currentVsLongTermVolume = currentVolume / avgVolume3M;

    let insights = [];

    // Add technical signals analysis first
    if (technicalSignals?.length > 0) {
      const buySignals = technicalSignals.filter(s => s.type.toUpperCase() === 'BUY');
      const sellSignals = technicalSignals.filter(s => s.type.toUpperCase() === 'SELL');
      
      // Get strongest signals
      const strongestBuy = buySignals.reduce((prev, curr) => 
        (prev?.strength || 0) > curr.strength ? prev : curr, null);
      const strongestSell = sellSignals.reduce((prev, curr) => 
        (prev?.strength || 0) > curr.strength ? prev : curr, null);

      if (strongestBuy) {
        insights.push(`A ${strongestBuy.name} pattern has been detected with ${(strongestBuy.strength * 100).toFixed(0)}% strength, suggesting potential upward movement.`);
      }
      if (strongestSell) {
        insights.push(`A ${strongestSell.name} pattern has been detected with ${(strongestSell.strength * 100).toFixed(0)}% strength, indicating possible downward pressure.`);
      }
    }

    // Price trend analysis - integrate with technical signals
    if (shortTermVsLongTermPrice > 1.05) {
      const goldenCross = technicalSignals?.find(s => s.name === 'Golden Cross with Volume');
      if (goldenCross) {
        insights.push("The 50-day moving average is above the 200-day moving average, confirmed by a Golden Cross signal, strongly suggesting a bullish trend.");
      } else {
        insights.push("The 50-day moving average is above the 200-day moving average, indicating a potential bullish trend.");
      }
    } else if (shortTermVsLongTermPrice < 0.95) {
      const deathCross = technicalSignals?.find(s => s.name === 'Death Cross with Volume');
      if (deathCross) {
        insights.push("The 50-day moving average is below the 200-day moving average, confirmed by a Death Cross signal, strongly suggesting a bearish trend.");
      } else {
        insights.push("The 50-day moving average is below the 200-day moving average, indicating a potential bearish trend.");
      }
    }

    // Volume trend analysis - integrate with gap signals
    if (currentVsShortTermVolume > 1.5 && currentVsLongTermVolume > 1.5) {
      const gapUp = technicalSignals?.find(s => s.name === 'Gap Up Momentum');
      const gapDown = technicalSignals?.find(s => s.name === 'Gap Down Momentum');
      
      if (priceChange > 0) {
        if (gapUp) {
          insights.push("The stock is up on significantly higher volume, with a confirmed Gap Up Momentum signal indicating strong buying pressure.");
        } else {
          insights.push("The stock is up on significantly higher volume than recent averages, suggesting strong buying pressure.");
        }
      } else {
        if (gapDown) {
          insights.push("The stock is down on significantly higher volume, with a confirmed Gap Down Momentum signal indicating increased selling pressure.");
        } else {
          insights.push("The stock is down on significantly higher volume than recent averages, potentially indicating increased selling pressure.");
        }
      }
    }

    // Support/Resistance analysis
    const supportBounce = technicalSignals?.find(s => s.name === 'Support Bounce');
    const resistanceRejection = technicalSignals?.find(s => s.name === 'Resistance Rejection');
    
    if (supportBounce) {
      insights.push(`Price bounced off support level with ${(supportBounce.strength * 100).toFixed(0)}% confidence, suggesting potential reversal from downtrend.`);
    }
    if (resistanceRejection) {
      insights.push(`Price rejected at resistance level with ${(resistanceRejection.strength * 100).toFixed(0)}% confidence, indicating possible trend reversal.`);
    }

    // Conclusion - integrate all signals
    let conclusion = "";
    const hasPositiveSignals = technicalSignals?.some(s => s.type.toUpperCase() === 'BUY');
    const hasNegativeSignals = technicalSignals?.some(s => s.type.toUpperCase() === 'SELL');
    
    if (priceChange > 0 && hasPositiveSignals) {
      conclusion = "Multiple technical indicators and price action suggest growing investor interest and potential further upside.";
    } else if (priceChange < 0 && hasNegativeSignals) {
      conclusion = "Technical signals and price action indicate sustained selling pressure, suggesting caution.";
    } else if (priceChange > 0 && hasNegativeSignals) {
      conclusion = "Despite price increase, technical signals suggest potential resistance ahead.";
    } else if (priceChange < 0 && hasPositiveSignals) {
      conclusion = "While price is declining, technical signals suggest potential support levels nearby.";
    }

    return insights.join(" ") + (conclusion ? " " + conclusion : "");
  };

  const determineBadgeType = (currentPrice, fiftyDayAvg, twoHundredDayAvg, volumeCurrent, volumeAvg10D, volumeAvg3M, technicalSignals) => {
    // First check for strong technical signals
    if (technicalSignals?.length > 0) {
      const strongestSignal = technicalSignals.reduce((prev, curr) => 
        (prev?.strength || 0) > curr.strength ? prev : curr, null);
      
      if (strongestSignal && strongestSignal.strength > 0.7) { // 70% threshold for strong signals
        if (strongestSignal.type.toUpperCase() === 'SELL') {
          if (strongestSignal.name.includes('Resistance')) return "Resistance Level";
          if (strongestSignal.name.includes('Death Cross')) return "Bearish Trend";
          return "Bearish Signal";
        } else {
          if (strongestSignal.name.includes('Support')) return "Support Level";
          if (strongestSignal.name.includes('Golden Cross')) return "Bullish Trend";
          return "Bullish Signal";
        }
      }
    }

    // If no strong technical signals, fall back to moving average analysis
    const shortTermVsLongTermPrice = fiftyDayAvg / twoHundredDayAvg;
    const currentVsShortTermVolume = volumeCurrent / volumeAvg10D;
    
    if (shortTermVsLongTermPrice > 1.05 && currentVsShortTermVolume > 1.2) {
      return "Bullish Trend";
    } else if (shortTermVsLongTermPrice < 0.95 && currentVsShortTermVolume > 1.2) {
      return "Bearish Trend";
    } else if (Math.abs(shortTermVsLongTermPrice - 1) <= 0.05) {
      return "Consolidating";
    }
    
    return "Neutral";
  };

  // useEffect Hook
  useEffect(() => {
    const priceMarkers = [
      {
        label: 'Current',
        value: currentPrice,
        position: calculateAdjustedPosition(currentPrice, adjustedPriceMin, adjustedPriceMax),
        markerStyle: currentMarkerStyle,
      },
      ...priceAverages.map((avg) => ({
        label: avg.label,
        value: avg.value,
        position: calculateAdjustedPosition(avg.value, adjustedPriceMin, adjustedPriceMax),
        markerStyle: averageMarkerStyle,
      })),
    ];

    // Updated Volume calculations
    const volumeValues = [volumeCurrent, volumeAvg10D, volumeAvg3M].filter(v => !isNaN(v));
    const volumeMin = Math.min(...volumeValues);
    const volumeMax = Math.max(...volumeValues);

    const volumeMarkers = [
      {
        label: 'Current',
        value: volumeCurrent,
        position: calculateAdjustedPosition(volumeCurrent, volumeMin, volumeMax),
        markerStyle: currentMarkerStyle,
      },
      {
        label: '10D Avg',
        value: volumeAvg10D,
        position: calculateAdjustedPosition(volumeAvg10D, volumeMin, volumeMax),
        markerStyle: averageMarkerStyle,
      },
      {
        label: '3M Avg',
        value: volumeAvg3M,
        position: calculateAdjustedPosition(volumeAvg3M, volumeMin, volumeMax),
        markerStyle: averageMarkerStyle,
      },
    ].filter(marker => !isNaN(marker.value));

    setPriceMarkerPositions(calculateMarkerPositions(priceMarkers));
    setVolumeMarkerPositions(calculateMarkerPositions(volumeMarkers));

    const combinedAnalysis = analyzePriceAndVolume(
      currentPrice,
      fiftyDayAvg,
      twoHundredDayAvg,
      volumeCurrent,
      volumeAvg10D,
      volumeAvg3M,
      stock.technicalSignals
    );
    setInterpretation(combinedAnalysis || "Price and volume patterns are within normal ranges without significant implications.");

    const badge = determineBadgeType(
      currentPrice,
      fiftyDayAvg,
      twoHundredDayAvg,
      volumeCurrent,
      volumeAvg10D,
      volumeAvg3M,
      stock.technicalSignals
    );
    setBadgeType(badge);
  }, [
    currentPrice,
    adjustedPriceMin,
    adjustedPriceMax,
    priceAverages,
    currentMarkerStyle,
    averageMarkerStyle,
    volumeCurrent,
    adjustedVolumeMin,
    volumeMax,
    volumeAverages,
    fiftyDayAvg,
    twoHundredDayAvg,
    volumeAvg3M,
    priceMin,
    priceMax,
    volumeAvg10D,
    stock.technicalSignals
  ]);

  // Helper function to format numbers
  const formatNumber = (num) => {
    if (num >= 1e9) return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    return num.toString();
  };

  // Add new mobile-specific helper function
  const isMobile = () => window.innerWidth < 768;

  // Update renderRangeBar function
  const renderRangeBar = (markers, isVolume = false) => {
    const mobile = isMobile();
    
    // Sort markers by position to handle overlapping
    const sortedMarkers = [...markers].sort((a, b) => a.position - b.position);
    
    // Calculate minimum spacing between markers (in percentage points)
    const minSpacing = mobile ? 15 : 10;
    
    // Adjust vertical positions to prevent overlap
    const adjustedMarkers = sortedMarkers.map((marker, index) => {
      let verticalPosition = 0;
      
      // Check distance to previous markers
      for (let i = 0; i < index; i++) {
        const distance = marker.position - sortedMarkers[i].position;
        if (distance < minSpacing) {
          verticalPosition = (sortedMarkers[i].verticalPosition + 1) % 3;
        }
      }
      
      return { ...marker, verticalPosition };
    });

    return (
      <div className="range-container">
        <div className="range-line"></div>

        {/* Min and Max Markers - only for Price Range */}
        {!isVolume && (
          <>
            <div className="marker marker-endpoint" style={{ left: '10px' }}>
              <div className="marker-circle"></div>
              <div className="marker-label marker-label-bottom">
                52W Low<br />
                {priceMin.toFixed(2)}
              </div>
            </div>
            <div className="marker marker-endpoint" style={{ right: '10px', left: 'auto' }}>
              <div className="marker-circle"></div>
              <div className="marker-label marker-label-bottom">
                52W High<br />
                {priceMax.toFixed(2)}
              </div>
            </div>
          </>
        )}

        {/* Other Markers */}
        {adjustedMarkers.map((marker, index) => {
          const labelPosition = marker.verticalPosition === 0 ? 'top' : 'bottom';
          const verticalOffset = marker.verticalPosition * (mobile ? 25 : 20);
          
          return (
            <div
              key={index}
              className="marker"
              style={{
                left: `calc(${marker.position}% + 10px)`,
                top: `calc(50% + ${verticalOffset}px)`,
              }}
            >
              <div className={marker.label === 'Current' ? 'marker-current' : 'marker-average'}></div>
              <div className={`marker-label marker-label-${labelPosition}`}>
                {marker.label}<br />
                {isVolume ? formatNumber(marker.value) : marker.value.toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getPlaceholderText = (type) => {
    switch (type) {
      case 'price':
        return "No significant price patterns detected. The stock is trading within its normal range.";
      case 'volume':
        return "Current trading volume is within normal levels compared to recent averages.";
      default:
        return "No significant patterns detected at this time.";
    }
  };

  const getBadgeClassName = (type) => {
    switch (type) {
      case 'Bullish Trend':
      case 'Bullish Signal':
      case 'Support Level':
        return 'badge badge-bullish';
      case 'Bearish Trend':
      case 'Bearish Signal':
      case 'Resistance Level':
        return 'badge badge-bearish';
      case 'Consolidating':
        return 'badge badge-consolidating';
      default:
        return 'badge badge-neutral';
    }
  };

  // Early return with CSS classes
  if (!isPriceDataValid && !isVolumeDataValid) {
    return (
      <div className="trading-range-container">
        <h3 className="title">Trading Range Analysis</h3>
        <p>Insufficient data to display trading range insights.</p>
      </div>
    );
  }

  // Component Render with CSS classes
  return (
    <div className="trading-range-container">
      <div className="graph-column">
        <h3 className="title">Trading Range Analysis</h3>

        {isPriceDataValid && (
          <div>
            <h4 className="subtitle">Price Range</h4>
            {renderRangeBar(priceMarkerPositions)}
          </div>
        )}

        {isVolumeDataValid && (
          <div>
            <h4 className="subtitle">Volume Range</h4>
            {renderRangeBar(volumeMarkerPositions, true)}
          </div>
        )}
      </div>

      <div className="insight-column">
        <h3 className="title">Insights</h3>
        {badgeType && (
          <div className={getBadgeClassName(badgeType)}>
            {badgeType}
          </div>
        )}
        <div className="insight-box">
          <h4 className="subtitle">Price and Volume Analysis</h4>
          <p className={`insight-text ${!interpretation ? 'placeholder-text' : ''}`}>
            {interpretation || getPlaceholderText()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TradingRangeInsights;

