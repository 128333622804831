import { formatPercentage, formatDoublePercentage, formatNumber } from '../components/utils';

export const INITIAL_SORT_CONFIG = { key: 'date', direction: 'ascending' };

export const DEFAULT_COLOR_SETTINGS = [
  {
    conditions: [
      { field: 'trafficChange', operator: '>', value: '0' },
      { field: 'stockChange', operator: '<=', value: '0' },
      { field: 'outlook', operator: '>', value: '0.7' }
    ],
    color: '#e6ffe6' // Light green
  },
  {
    conditions: [
      { field: 'trafficChange', operator: '<', value: '0' },
      { field: 'stockChange', operator: '>=', value: '0' }
    ],
    color: '#ffe6e6' // Light red
  },
  {
    conditions: [], // Empty conditions array will match all rows
    color: 'transparent' // Default background for rows that don't match any other rules
  }
];

// Define all possible columns with necessary properties
export const ALL_COLUMNS = [
  // Fixed Columns
  {
    key: 'date',
    label: 'Date',
    tooltip: 'Next earnings date or economic event date',
    fields: ['date'],
    sortField: 'date',
    format: 'date',
    isFixed: true,
    isSortable: true,
    sortFunction: (a, b) => {
      // If both dates are null/undefined, maintain their relative position
      if (!a.date && !b.date) return 0;
      // If only a's date is null/undefined, it should go last
      if (!a.date) return 1;
      // If only b's date is null/undefined, it should go last
      if (!b.date) return -1;
      // Normal date comparison for valid dates
      return new Date(a.date) - new Date(b.date);
    }
  },
  {
    key: 'name',
    label: 'Company',
    tooltip: 'Company name and ticker symbol',
    fields: ['name', 'tickerSymbol'],
    sortField: 'name',
    format: null,
    isFixed: true,
    isSortable: true,
    isCompanyColumn: true,
  },
  {
    key: 'actions',
    label: '',
    isFixed: true,
    tooltip: 'Column settings',
    isHidden: true,
    fields: [],
    isActionsColumn: true,
    isSortable: false,  // Set isSortable to false
  },
  // Configurable Columns
  {
    key: 'stockPrice',
    label: 'Price',
    tooltip: 'Current stock price and today\'s change',
    fields: ['stockPrice', 'regularMarketChangePercent'],
    sortField: 'stockPrice',
    format: 'currencyWithChange', // Custom format to handle price and percentage change
    isSortable: true,
  },
  {
    key: 'stockChange',
    label: 'Since Earnings',
    tooltip: 'Current stock price and % change since earnings',
    fields: ['stockPrice', 'stockChange'],
    sortField: 'stockChange',
    format: null,
    isSortable: true,
  },
  {
    key: 'trafficChange',
    label: 'Traffic',
    tooltip: 'Website traffic and percentage change over the past 3 months',
    fields: ['trafficChange'],
    sortField: 'trafficChange',
    format: 'percentage',
    isSortable: true,
  },
  {
    key: 'positionsPercentage',
    label: 'Job Openings',
    tooltip: 'Open positions and % relative to total employees',
    fields: ['positionsPercentage'],
    sortField: 'positionsPercentage',
    format: 'percentage',
    isSortable: true,
  },
  {
    key: 'outlook',
    label: 'Employee Outlook',
    tooltip: "Employee sentiment about the company's future",
    fields: ['outlook'],
    sortField: 'outlook',
    isSortable: true,
  },
  {
    key: 'dividendYield',
    label: 'Dividend Yield',
    tooltip: 'Annual dividend yield',
    fields: ['dividendYield'],
    sortField: 'dividendYield',
    isSortable: true,
  },
  {
    key: 'marketCap',
    label: 'Market Cap',
    tooltip: 'Total market capitalization',
    fields: ['marketCap'],
    sortField: 'marketCap',
    format: 'currency',
    isSortable: true,
  },
  // Action Column (for row expand handler)

  {
    key: 'pegRatio',
    label: 'PEG Ratio',
    tooltip: 'Price/Earnings to Growth ratio - lower values suggest better value',
    fields: ['pegRatio'],
    sortField: 'pegRatio',
    format: (value) => formatNumber(value, 2),
    isSortable: true,
  },
  {
    key: 'growth',
    label: 'Growth',
    tooltip: 'Revenue growth rate',
    fields: ['revenueGrowth'],
    sortField: 'revenueGrowth',
    format: (value) => {
        console.log('growth format input:', value);
        return formatPercentage(value);
    },
    isSortable: true,
  },
  {
    key: 'shortFloat',
    label: 'Short %',
    tooltip: 'Percentage of float shares sold short',
    fields: ['shortPercentOfFloat'],
    sortField: 'shortPercentOfFloat',
    format: (value) => {
        console.log('shortFloat format input:', value);
        return formatPercentage(value);
    },
    isSortable: true,
  },
  {
    key: 'margins',
    label: 'Margins',
    tooltip: 'Gross margin with operating margin change',
    fields: ['grossMargins', 'operatingMargins'],
    sortField: 'grossMargins',
    format: null,
    isSortable: true,
  },
  {
    key: 'beta',
    label: 'Beta',
    tooltip: 'Stock volatility relative to the market',
    fields: ['beta'],
    sortField: 'beta',
    format: (value) => formatNumber(value, 2),
    isSortable: true,
  },
  {
    key: 'technicalSignals',
    label: 'Signals',
    tooltip: 'Technical analysis signals',
    fields: ['technicalSignals'],
    sortField: 'latestSignal.strength',
    isSortable: true,
    sortFunction: (a, b) => {
      // First, check for buy signals
      const aHasBuy = a.technicalSignals?.some(s => s.type.toUpperCase() === 'BUY');
      const bHasBuy = b.technicalSignals?.some(s => s.type.toUpperCase() === 'BUY');
      
      if (aHasBuy !== bHasBuy) return bHasBuy ? 1 : -1;
      
      // If both have same signal types, compare by highest strength
      const aStrength = Math.max(...(a.technicalSignals?.map(s => s.strength) || [0]));
      const bStrength = Math.max(...(b.technicalSignals?.map(s => s.strength) || [0]));
      
      return bStrength - aStrength;
    }
  },
  {
    key: 'extendedHours',
    label: 'Extended Hours Price',
    tooltip: 'Pre-market or Post-market price and percentage change',
    fields: ['preMarketPrice', 'preMarketChangePercent', 'postMarketPrice', 'postMarketChangePercent'],
    sortField: 'extendedHoursChangePercent',
    isSortable: true,
    sortFunction: (a, b) => {
      const getChangePercent = (stock) => {
        return stock.preMarketChangePercent || stock.postMarketChangePercent || 0;
      };
      
      return getChangePercent(b) - getChangePercent(a);
    },
    format: (row) => {
      // If we have pre-market data, use it
      if (row.preMarketPrice) {
        return {
          price: row.preMarketPrice,
          change: row.preMarketChangePercent || 0,
          type: 'pre'
        };
      }
      // Otherwise if we have post-market data, use it
      if (row.postMarketPrice) {
        return {
          price: row.postMarketPrice,
          change: row.postMarketChangePercent || 0,
          type: 'post'
        };
      }
      // No extended hours data available
      return null;
    }
  },
  {
    key: 'industry',
    label: 'Industry',
    tooltip: 'Company industry classification',
    fields: ['industry'],
    sortField: 'industry',
    isSortable: true,
    format: null,
  },
  {
    key: 'notes',
    label: 'Notes',
    tooltip: 'Personal notes about this stock (only visible in watchlist)',
    fields: ['notes'],
    sortField: 'notes',
    isSortable: true,
    isEditable: true,
    format: null,
    watchlistOnly: true, // Flag to indicate this column is only for watchlist
  },
];

// Define default columns to be displayed
export const DEFAULT_COLUMNS = [
  ALL_COLUMNS.find((col) => col.key === 'date'),
  ALL_COLUMNS.find((col) => col.key === 'name'),
  ALL_COLUMNS.find((col) => col.key === 'industry'),
  ALL_COLUMNS.find((col) => col.key === 'stockChange'),
  ALL_COLUMNS.find((col) => col.key === 'extendedHours'),
  ALL_COLUMNS.find((col) => col.key === 'trafficChange'),
  ALL_COLUMNS.find((col) => col.key === 'growth'),
  ALL_COLUMNS.find((col) => col.key === 'outlook'),
  ALL_COLUMNS.find((col) => col.key === 'technicalSignals'),
  // Remove notes from default columns as we'll add it dynamically in the watchlist tab
  // Exclude the actions column here
];

// Optionally, export FIELD_NAMES if used elsewhere
export const FIELD_NAMES = ALL_COLUMNS.reduce((fields, column) => {
  column.fields.forEach((field) => {
    if (!fields.includes(field)) {
      fields.push(field);
    }
  });
  return fields;
}, []);

