import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { DEBUG } from '../config/debug';
import PropTypes from 'prop-types';

export const MARKET_CAP_TIERS = [
  { 
    id: 'mega', 
    label: 'Mega Cap (>$200B)', 
    min: 200e9,  // $200 billion
    max: Infinity 
  },
  { 
    id: 'large', 
    label: 'Large Cap ($10B-$200B)', 
    min: 10e9,   // $10 billion
    max: 200e9 
  },
  { 
    id: 'mid', 
    label: 'Mid Cap ($2B-$10B)', 
    min: 2e9,    // $2 billion
    max: 10e9 
  },
  { 
    id: 'small', 
    label: 'Small Cap ($300M-$2B)', 
    min: 300e6,  // $300 million
    max: 2e9 
  },
  { 
    id: 'micro', 
    label: 'Micro Cap (<$300M)', 
    min: 0,
    max: 300e6 
  },
  { 
    id: 'unknown', 
    label: 'Unknown Market Cap', 
    min: null, 
    max: null 
  }
];

const MarketCapFilter = ({ activeFilters, onApplyFilters }) => {
  console.log('MarketCapFilter: Initializing with:', {
    activeFilters: activeFilters,
    hasApplyFilters: !!onApplyFilters
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTiers, setSelectedTiers] = useState(activeFilters || []);
  
  const containerRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const dropdownRef = useRef(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] }}],
  });

  useEffect(() => {
    if (DEBUG.filters) {
      console.log('MarketCapFilter: activeFilters updated:', activeFilters);
    }
    setSelectedTiers(activeFilters || []);
  }, [activeFilters]);

  const handleSelectAll = () => {
    setSelectedTiers(MARKET_CAP_TIERS.map(tier => tier.id));
  };

  const handleTierChange = (tierId) => {
    setSelectedTiers(prev => 
      prev.includes(tierId) 
        ? prev.filter(id => id !== tierId)
        : [...prev, tierId]
    );
  };

  const handleApply = () => {
    if (DEBUG.filters) {
      console.log('MarketCapFilter: Applying filters:', {
        marketCap: selectedTiers
      });
    }
    
    onApplyFilters({
      marketCap: selectedTiers
    });
    setIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedTiers(activeFilters || []);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <div
        ref={setReferenceElement}
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
      >
        <FontAwesomeIcon 
          icon={faDollarSign} 
          className={`text-lg ${selectedTiers.length !== MARKET_CAP_TIERS.length ? 'text-blue-500' : 'text-gray-600'}`}
        />
      </div>

      {isOpen && createPortal(
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-72 bg-white shadow-lg ring-1 ring-black/5 rounded-lg z-50 divide-y divide-gray-100"
        >
          <div className="px-4 py-3 border-b">
            <label className="flex items-center gap-3 hover:bg-gray-50 rounded-md p-2 cursor-pointer transition-colors duration-200">
              <div className="flex items-center justify-center w-5 h-5">
                <input
                  type="checkbox"
                  checked={selectedTiers.length === MARKET_CAP_TIERS.length}
                  onChange={handleSelectAll}
                  className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition-colors duration-200"
                />
              </div>
              <span className="font-medium text-sm text-gray-700">SELECT ALL</span>
            </label>
          </div>

          <div className="max-h-60 overflow-y-auto py-2">
            {MARKET_CAP_TIERS.map((tier) => (
              <label
                key={tier.id}
                className="flex items-center gap-3 px-4 py-2 hover:bg-gray-50 cursor-pointer transition-colors duration-200"
              >
                <div className="flex items-center justify-center w-5 h-5">
                  <input
                    type="checkbox"
                    checked={selectedTiers.includes(tier.id)}
                    onChange={() => handleTierChange(tier.id)}
                    className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 transition-colors duration-200"
                  />
                </div>
                <span className="text-sm text-gray-700">{tier.label}</span>
              </label>
            ))}
          </div>

          <div className="p-4 bg-gray-50 rounded-b-lg flex justify-between items-center">
            <div className="text-sm text-gray-500 font-medium">
              {selectedTiers.length} Selected
            </div>
            <div className="flex gap-2">
              <button
                onClick={handleCancel}
                className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 transition-colors duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleApply}
                className="px-4 py-1.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 font-medium"
              >
                Apply
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

MarketCapFilter.propTypes = {
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  onApplyFilters: PropTypes.func.isRequired
};

MarketCapFilter.defaultProps = {
  activeFilters: []
};

export default React.memo(MarketCapFilter, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.activeFilters) === JSON.stringify(nextProps.activeFilters);
}); 