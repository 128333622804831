import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronDown, FiChevronUp, FiTrendingUp, FiClock, FiAlertTriangle, FiActivity, FiTrendingDown, FiMinusCircle, FiTarget } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import CONFIG from '../config/config';
import NewsRow from './NewsRow';
import { formatPercentage } from './utils';
import Tooltip from './Tooltip';
import { authenticatedFetch, publicFetch } from '../utils/authenticatedFetch';
import '../styles/MarketAnalysis.css';
import DisclaimerMessage from './DisclaimerMessage';
import MacroScenariosSection from './MacroScenariosSection';
import BondInsightsSection from './BondInsightsSection';
import FearGreedSection from './FearGreed';
import MarketOverviewSection from './MarketOverviewSection';

const tierLabels = {
  mega: <span className="market-cap-label">Mega Cap <span className="market-cap-range">(&gt;$200B)</span></span>,
  large: <span className="market-cap-label">Large Cap <span className="market-cap-range">($10B-$200B)</span></span>,
  mid: <span className="market-cap-label">Mid Cap <span className="market-cap-range">($2B-$10B)</span></span>,
  small: <span className="market-cap-label">Small Cap <span className="market-cap-range">($300M-$2B)</span></span>,
  micro: <span className="market-cap-label">Micro Cap <span className="market-cap-range">(&lt;$300M)</span></span>
};

// Add new error boundary component
const ErrorBoundarySection = ({ children, error, isLoading }) => {
  if (isLoading) return <div className="p-3 text-gray-500">Loading...</div>;
  if (error) return (
    <div className="p-3 text-red-500 text-sm">
      <FiAlertTriangle className="inline mr-2" />
      Unable to load data: {error.message || 'Unknown error'}
    </div>
  );
  return children;
};

// Separate Market Pulse section
const MarketPulseSection = ({ data, isLoading, error }) => {
  return (
    <ErrorBoundarySection error={error} isLoading={isLoading}>
      {data && getMarketPulseAnalysis(data)}
    </ErrorBoundarySection>
  );
};

// Move MarketAnalysisSection component definition before its usage
const MarketAnalysisSection = ({ data, isLoading, error }) => {
  if (!data && !error && !isLoading) return null;
  
  return (
    <ErrorBoundarySection error={error} isLoading={isLoading}>
      {data?.analysis && (
        <>
          <div className="py-2.5 text-gray-600">
            {data.analysis.Summary?.marketOutlook || 'No market outlook available'}
          </div>
          
          {/* Add Sector Outlook */}
          {data.analysis.Summary?.sectorOutlook && (
            <div className="mt-3 sector-outlook-container">
              <h4 className="text-sm font-semibold">Sector Outlook:</h4>
              <div className="sector-outlook">
                {Object.entries(data.analysis.Summary.sectorOutlook).map(([sector, sectorData]) => (
                  <Tooltip 
                    key={sector} 
                    text={
                      <div className="max-w-xs">
                        <div className="font-semibold mb-1">{sectorData.analysis}</div>
                        <div className="mt-2">
                          <span className="font-medium">Key Drivers:</span>
                          <ul className="list-disc pl-4 mt-1">
                            {sectorData.keyDrivers.map((driver, idx) => (
                              <li key={idx}>{driver}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    }
                  >
                    <div className={`sector-badge ${sectorData.sentiment}`}>
                      <span className="font-medium capitalize">{sector}</span>
                      <span className="mx-1">•</span>
                      <span className="capitalize">{sectorData.sentiment}</span>
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </ErrorBoundarySection>
  );
};

// Move getMarketPulseAnalysis outside of MarketAnalysisRow component
const getMarketPulseAnalysis = (rawData) => {
  if (!rawData?.data) return null;
  
  const {
    numPriceIncreases,
    numPriceDecreases,
    topIndustriesIncreases,
    topIndustriesDecreases,
    marketCapTiers
  } = rawData.data;
  
  return (
    <div className="space-y-4">
      {/* Market Cap Tiers Table */}
      {marketCapTiers && (
        <div className="overflow-x-auto">
          <table className="min-w-full text-sm">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-3 py-2 text-left font-medium text-gray-700">Market Cap</th>
                <th className="px-3 py-2 text-right font-medium text-green-700">Up %</th>
                <th className="px-3 py-2 text-right font-medium text-red-700">Down %</th>
                <th className="px-3 py-2 text-right font-medium text-green-700">Avg Up</th>
                <th className="px-3 py-2 text-right font-medium text-red-700">Avg Down</th>
                <th className="px-3 py-2 text-right font-medium text-blue-700">Near High</th>
                <th className="px-3 py-2 text-right font-medium text-orange-700">Near Low</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {marketCapTiers.map((tier) => (
                <tr key={tier.market_cap_tier} className="hover:bg-gray-50">
                  <td className="px-3 py-2">{tierLabels[tier.market_cap_tier]}</td>
                  <td className="px-3 py-2 text-right">
                    <span className={tier.increasing_percentage >= 50 ? 'text-green-600' : 'text-gray-600'}>
                      {formatPercentage(tier.increasing_percentage)}
                    </span>
                  </td>
                  <td className="px-3 py-2 text-right">
                    <span className="text-red-600">
                      {formatPercentage(100 - tier.increasing_percentage)}
                    </span>
                  </td>
                  <td className="px-3 py-2 text-right text-green-600">
                    {formatPercentage(tier.avg_increase)}
                  </td>
                  <td className="px-3 py-2 text-right text-red-600">
                    {formatPercentage(tier.avg_decrease)}
                  </td>
                  <td className="px-3 py-2 text-right text-blue-600">
                    {formatPercentage(tier.near_high_percentage)}
                  </td>
                  <td className="px-3 py-2 text-right text-orange-600">
                    {formatPercentage(tier.near_low_percentage)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      <div className="market-sectors">
        <div className="sector-group">
          <span className="sector-label sector-label-leading">Leading:</span>
          <span className="sector-list">
            {topIndustriesIncreases
              ?.slice(0, 3)
              .map(item => `${item.industry} (${Math.round(item.percentage)}%)`)
              .join(', ')}
          </span>
        </div>
        <div className="sector-group">
          <span className="sector-label sector-label-lagging">Lagging:</span>
          <span className="sector-list">
            {topIndustriesDecreases
              ?.slice(0, 3)
              .map(item => `${item.industry} (${Math.round(item.percentage)}%)`)
              .join(', ')}
          </span>
        </div>
      </div>
    </div>
  );
};

const MarketCycleSection = ({ marketCycle }) => {
  if (!marketCycle) return null;

  const getConfidenceBadge = (confidence) => {
    const colors = {
      HIGH: 'bg-green-100 text-green-800',
      MEDIUM: 'bg-yellow-100 text-yellow-800',
      LOW: 'bg-red-100 text-red-800'
    };
    return `inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${colors[confidence]}`;
  };

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <div className="flex items-center justify-between mb-3">
        <h3 className="text-lg font-medium text-gray-900">Market Cycle</h3>
        <span className={getConfidenceBadge(marketCycle.confidence)}>
          {marketCycle.confidence} Confidence
        </span>
      </div>
      <p className="text-gray-700">{marketCycle.description}</p>
      {marketCycle.transition.transitioning && (
        <div className="mt-2 text-sm text-gray-600">
          <span className="font-medium">Transition: </span>
          {marketCycle.transition.direction} ({marketCycle.transition.confidence} confidence)
        </div>
      )}
    </div>
  );
};

const phaseDetails = {
  EARLY_RECOVERY: {
    sectors: ['Financials', 'Consumer Discretionary', 'Industrials'],
    description: 'Leadership from rate-sensitive and cyclical sectors'
  },
  EXPANSION: {
    sectors: ['Technology', 'Materials', 'Energy'],
    description: 'Growth and commodity sectors lead'
  },
  LATE_CYCLE: {
    sectors: ['Energy', 'Materials', 'Healthcare'],
    description: 'Defensive growth and inflation beneficiaries'
  },
  CONTRACTION: {
    sectors: ['Utilities', 'Consumer Staples', 'Healthcare'],
    description: 'Defensive sectors outperform'
  }
};

const MarketPhaseVisual = ({ currentPhase, confidence }) => {
  const phases = ['EARLY_RECOVERY', 'EXPANSION', 'LATE_CYCLE', 'CONTRACTION'];
  
  return (
    <div className="market-cycle-indicator">
      {phases.map((phase, index) => (
        <div key={phase} className={`cycle-phase ${phase === currentPhase ? 'active-phase' : ''}`}>
          <div className="phase-header">
            <div className="phase-number">{index + 1}</div>
            <div className="phase-name">
              {phase.replace('_', ' ')}
            </div>
            {phase === currentPhase && (
              <div className="confidence-badge">{confidence} Confidence</div>
            )}
          </div>
          <div className="phase-details">
            <div className="typical-sectors">
              {phaseDetails[phase].sectors.join(' • ')}
            </div>
            <div className="phase-description text-xs text-gray-600">
              {phaseDetails[phase].description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const SectorTable = ({ sectors, title }) => {
  return (
    <div className="sector-table-container mb-4">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <table className="sector-table w-full">
        <thead>
          <tr>
            <th>Sector</th>
            <th>Daily Change</th>
            <th>Weekly</th>
            <th>Monthly</th>
          </tr>
        </thead>
        <tbody>
          {sectors.map((sector) => (
            <tr key={sector.sector}>
              <td>{sector.sector}</td>
              <td className={sector.daily_momentum >= 0 ? 'text-green-600' : 'text-red-600'}>
                {formatPercentage(sector.daily_momentum)}
              </td>
              <td className={sector.weekly_momentum >= 0 ? 'text-green-600' : 'text-red-600'}>
                {formatPercentage(sector.weekly_momentum)}
              </td>
              <td className={sector.monthly_momentum >= 0 ? 'text-green-600' : 'text-red-600'}>
                {formatPercentage(sector.monthly_momentum)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const MarketSummarySection = ({ interpretation }) => (
  <div className="market-summary">
    <div className="summary-card">
      <h3 className="summary-title">Market Overview</h3>
      <p className="summary-text">{interpretation.summary}</p>
      
      <div className="key-observations">
        {interpretation.key_observations.map((obs, index) => (
          <div key={index} className="observation-card">
            <div className="observation-header">Key Observation {index + 1}</div>
            <div className="observation-content">{obs.observation}</div>
            <div className="observation-implication">{obs.implication}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const SectorHighlights = ({ highlights }) => (
  <div className="sector-highlights">
    <div className="highlight-card">
      <div className="highlight-section">
        <h4 className="highlight-title">
          <FiTrendingUp className="icon-green" /> Leadership
        </h4>
        <p>{highlights.leadership}</p>
      </div>
      
      <div className="highlight-section">
        <h4 className="highlight-title">
          <FiTrendingDown className="icon-red" /> Weakness
        </h4>
        <p>{highlights.weakness}</p>
      </div>
      
      <div className="highlight-section">
        <h4 className="highlight-title">
          <FiActivity className="icon-blue" /> Notable Changes
        </h4>
        <p>{highlights.notable_changes}</p>
      </div>
    </div>
  </div>
);

const MarketHeader = ({ phase, isExpanded, setIsExpanded, marketStats, fearAndGreedData }) => {
  const numIncreasing = marketStats?.data?.numPriceIncreases || 0;
  const numDecreasing = marketStats?.data?.numPriceDecreases || 0;
  const total = numIncreasing + numDecreasing;
  
  const increasingPercent = total ? Math.round((numIncreasing / total) * 100) : 0;
  const decreasingPercent = total ? Math.round((numDecreasing / total) * 100) : 0;
  
  // Get fear & greed score and rating with proper fallbacks
  const fearGreedScore = fearAndGreedData?.current?.score ?? 0;
  const fearGreedRating = fearAndGreedData?.current?.rating?.toLowerCase() ?? 'neutral';

  // Debug log to verify the data
  console.log('Fear & Greed Data:', {
    score: fearGreedScore,
    rating: fearGreedRating,
    rawData: fearAndGreedData
  });

  return (
    <div className="market-header" onClick={() => setIsExpanded(!isExpanded)}>
      <div className="header-left">
        <h2 className="market-title">Market Analysis</h2>
      </div>
      <div className="header-center flex items-center space-x-6">
        <div className="market-breadth flex space-x-4">
          <div className="market-header-indicator market-header-advancing">
            <FiTrendingUp /> {increasingPercent}% Advancing
          </div>
          <div className="market-header-indicator market-header-declining">
            <FiTrendingDown /> {decreasingPercent}% Declining
          </div>
        </div>
        <div className="tooltip-wrapper">
          <Tooltip text={
            <div className="max-w-xs">
              <div className="font-medium mb-1">Fear & Greed Index</div>
              <div className="text-sm mb-2">
                Composite measure of market sentiment based on 7 key indicators including market momentum, 
                volatility, junk bond demand, and put/call ratios.
              </div>
              <div className="text-sm italic">{getSentimentDescription(fearGreedRating)}</div>
              <div className="mt-2 text-xs text-gray-500">Score: {fearGreedScore.toFixed(1)} / 100</div>
            </div>
          }>
            <div className={`market-header-indicator market-header-neutral ${fearGreedRating}`}>
              Fear & Greed: {typeof fearGreedScore === 'number' ? fearGreedScore.toFixed(1) : '0.0'} ({fearGreedRating})
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="header-right">
        <div className="phase-indicator">
          {phase?.replace('_', ' ')} Phase
          <span className="expand-icon">
            {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
          </span>
        </div>
      </div>
    </div>
  );
};

// Update the getSentimentColorClass helper function
const getSentimentColorClass = (rating) => {
  if (!rating) return 'text-gray-600';
  
  const colors = {
    'extreme fear': 'text-red-600',
    'fear': 'text-orange-600',
    'neutral': 'text-gray-600',
    'greed': 'text-green-600',
    'extreme greed': 'text-emerald-600'
  };
  return colors[rating.toLowerCase()] || 'text-gray-600';
};

// Add this near the other helper functions at the top of the file
const getSentimentDescription = (rating) => {
  const descriptions = {
    'extreme fear': 'Indicates extremely pessimistic market sentiment, historically a contrarian buy signal',
    'fear': 'Shows elevated market pessimism and risk aversion',
    'neutral': 'Balanced market sentiment without strong bias',
    'greed': 'Indicates elevated market optimism and risk appetite',
    'extreme greed': 'Shows extremely optimistic sentiment, historically a contrarian sell signal'
  };
  return descriptions[rating?.toLowerCase()] || '';
};

const MarketAnalysisRow = ({ 
  analysis, 
  guidance, 
  relatedEquities, 
  onAddSymbol, 
  userSettings, 
  onSettingsChange,
  news
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Move the fear & greed query here
  const { data: fearAndGreedData } = useQuery({
    queryKey: ['fearAndGreed'],
    queryFn: async () => {
      const response = await publicFetch('https://api.1stprinciples.pro/fear-greed?full=true');
      if (!response.ok) throw new Error('Failed to fetch fear and greed data');
      return response.json();
    },
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000
  });

  const { data: marketStats } = useQuery({
    queryKey: ['dailyMovementAnalytics'],
    queryFn: async () => {
      const response = await publicFetch(CONFIG.API_URLS.DAILY_MOVEMENT_ANALYTICS);
      if (!response.ok) throw new Error('Failed to fetch daily movement analytics');
      return response.json();
    },
    staleTime: 300000,
    refetchInterval: 300000
  });

  const { data: sectorRotationData, isLoading, error } = useQuery({
    queryKey: ['sectorRotation'],
    queryFn: async () => {
      const response = await publicFetch(CONFIG.API_URLS.SECTOR_ROTATION);
      if (!response.ok) throw new Error('Failed to fetch sector rotation data');
      return response.json();
    }
  });

  const { data: marketOverviewData, isLoading: isLoadingMarketOverview, error: marketOverviewError } = useQuery({
    queryKey: ['marketOverview'],
    queryFn: async () => {
      const response = await publicFetch('https://api.1stprinciples.pro/market-overview');
      if (!response.ok) throw new Error('Failed to fetch market overview');
      return response.json();
    },
    staleTime: 300000,           // let's keep 5 minutes staleness for now
    refetchInterval: 300000      // refetch every 5 minutes
  });

  console.log('Market Overview Data:', marketOverviewData, 'Error:', marketOverviewError);

  // Group sectors by their momentum state
  const groupedSectors = React.useMemo(() => {
    if (!sectorRotationData?.sectorDetails) return {};
    
    return sectorRotationData.sectorDetails.reduce((acc, sector) => {
      const state = sector.momentum_state;
      if (!acc[state]) {
        acc[state] = [];
      }
      acc[state].push(sector); // Pass the entire sector object
      return acc;
    }, {});
  }, [sectorRotationData]);

  console.log('Grouped sectors:', groupedSectors);

  const { 
    data: macroScenariosData, 
    isLoading: isLoadingScenarios, 
    error: scenariosError 
  } = useQuery({
    queryKey: ['macroScenarios'],
    queryFn: async () => {
      const response = await publicFetch(CONFIG.API_URLS.MACRO_SCENARIOS);
      if (!response.ok) throw new Error('Failed to fetch macro scenarios');
      return response.json();
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    refetchInterval: 12 * 60 * 60 * 1000, // 12 hours
  });

  if (isLoading) return <div className="loading-spinner">Loading...</div>;
  if (error) return <div className="error-message">Error: {error.message}</div>;

  return (
    <div className="market-analysis-container">
      <MarketHeader 
        phase={sectorRotationData?.marketCycle?.currentPhase}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        marketStats={marketStats}
        fearAndGreedData={fearAndGreedData}
      />
      
      {isExpanded && (
        <div className="market-content">
          <MarketOverviewSection
            data={marketOverviewData}
            isLoading={isLoadingMarketOverview}
            error={marketOverviewError}
          />

          <FearGreedSection 
            fearAndGreedData={fearAndGreedData}
          />
          <MarketPhaseVisual 
            currentPhase={sectorRotationData?.marketCycle?.currentPhase}
            confidence={sectorRotationData?.marketCycle?.confidence}
          />

          <MarketSummarySection interpretation={sectorRotationData?.interpretation} />
          
          <SectorHighlights highlights={sectorRotationData?.interpretation.sector_highlights} />

          <div className="market-risks-opportunities">
            <div className="risk-section">
              <h4 className="section-title">
                <FiAlertTriangle className="icon-warning" /> Key Risks
              </h4>
              <p>{sectorRotationData?.interpretation.market_phase.risks}</p>
            </div>
            
            <div className="opportunity-section">
              <h4 className="section-title">
                <FiTarget className="icon-success" /> Opportunities
              </h4>
              <p>{sectorRotationData?.interpretation.market_phase.opportunities}</p>
            </div>
          </div>

          <div className="sector-performance">
            {Object.entries(groupedSectors).map(([state, sectors]) => {
              if (!sectors || sectors.length === 0) return null;
              
              const titles = {
                'WEAK_UP': 'Improving Sectors',
                'NEUTRAL': 'Neutral Sectors',
                'WEAK_DOWN': 'Weakening Sectors'
              };
              
              return (
                <SectorTable 
                  key={state} 
                  sectors={sectors} 
                  title={titles[state] || state}
                />
              );
            })}
          </div>

          <DisclaimerMessage 
            message="Market analysis includes AI-generated insights. As with all LLM outputs, please verify information independently and use as one of many data points in your research."
            lastUpdated={sectorRotationData?.timestamp}
            className="mt-4 mb-2"
          />
        </div>
      )}

      <MacroScenariosSection 
        scenarios={macroScenariosData?.scenarios || []}
        isLoading={isLoadingScenarios}
        error={scenariosError}
        lastUpdated={macroScenariosData?.timestamp}
      />
      
      <BondInsightsSection
        isLoading={false}
        error={null}
      />
      
      <NewsRow 
        onAddSymbol={onAddSymbol}
        userSettings={userSettings}
        onSettingsChange={onSettingsChange}
        news={news}
      />
    </div>
  );
};

export default MarketAnalysisRow;
