import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const EditableNotes = ({ 
  tickerSymbol, 
  initialValue = '', 
  onNoteChange 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState(initialValue);
  const inputRef = useRef(null);

  // Create a debounced save function to avoid too many saves
  const debouncedSave = useRef(
    debounce((value) => {
      if (onNoteChange) {
        onNoteChange(tickerSymbol, value);
      }
    }, 500)
  ).current;

  useEffect(() => {
    // Update note if initialValue changes (e.g. from parent)
    setNote(initialValue);
  }, [initialValue]);

  useEffect(() => {
    // Focus the input when editing starts
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setNote(newValue);
    debouncedSave(newValue);
  };

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // Ensure the most recent value is saved
    onNoteChange(tickerSymbol, note);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // Save on Enter key
      e.preventDefault();
      setIsEditing(false);
      onNoteChange(tickerSymbol, note);
    } else if (e.key === 'Escape') {
      // Cancel on Escape key
      setIsEditing(false);
      setNote(initialValue);
    }
  };

  return (
    <div className="notes-container">
      {isEditing ? (
        <textarea
          ref={inputRef}
          value={note}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
          rows={3}
          placeholder="Add notes..."
        />
      ) : (
        <div 
          onClick={handleStartEditing}
          className="w-full px-2 py-1 min-h-[40px] hover:bg-gray-50 cursor-text"
        >
          {note ? (
            <span className="text-gray-800">{note}</span>
          ) : (
            <span className="text-gray-400 italic">Add notes...</span>
          )}
        </div>
      )}
    </div>
  );
};

export default EditableNotes; 