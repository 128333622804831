import React from 'react';
import PropTypes from 'prop-types';

const GaugeChart = ({ 
  value = 50, 
  ranges = [0, 25, 50, 75, 100], 
  arcColors = ['#EF4444', '#F59E0B', '#6B7280', '#10B981', '#059669'],
  label = '',
  hideText = false
}) => {
  // Validation
  if (!Array.isArray(ranges)) {
    console.error('GaugeChart: ranges must be an array');
    return null;
  }

  const percentage = ((value - ranges[0]) / (ranges[ranges.length - 1] - ranges[0])) * 100;
  
  return (
    <div className="relative h-full w-full">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-32 h-32 rounded-full border-8 border-gray-200 relative overflow-hidden">
          <div 
            className="absolute bottom-0 left-0 right-0 transition-all duration-500"
            style={{
              height: `${percentage}%`,
              background: `linear-gradient(to top, ${arcColors.join(', ')})`,
              borderRadius: '0 0 9999px 9999px'
            }}
          />
          <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold">
            {value.toFixed(0)}
          </div>
        </div>
      </div>
    </div>
  );
};

GaugeChart.propTypes = {
  value: PropTypes.number,
  ranges: PropTypes.arrayOf(PropTypes.number),
  arcColors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  hideText: PropTypes.bool
};

GaugeChart.defaultProps = {
  value: 50,
  ranges: [0, 25, 50, 75, 100],
  arcColors: ['#EF4444', '#F59E0B', '#6B7280', '#10B981', '#059669'],
  label: '',
  hideText: false
};

export default GaugeChart; 