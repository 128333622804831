import React, { useRef, useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEllipsisV,
  faStar,
  faBan,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Favicon from "./Favicon";
import AiAnalysisPanel from "./AiAnalysisPanel";
import EditableNotes from "./EditableNotes";
import "./CompanyRow.css";
import {
  formatDate,
  daysUntilEarnings,
  getChangeClass,
  formatChange,
  getRowStyle,
  formatNumberAbbreviated, // Import it from utils.js
  formatMarketCap, // Import if you're using it
} from "./utils";
import EarningsBadge from "./EarningsBadge";
import TechnicalSignalIcons from './TechnicalSignalIcons';
import { FaNewspaper } from "react-icons/fa";
import CONFIG from '../config/config';
import { authenticatedFetch } from '../utils/authenticatedFetch';
import { publicFetch } from '../utils/authenticatedFetch';
import { Tooltip } from "react-tooltip";

const TrafficCell = ({ trafficData, trafficChange }) => {
  if (!trafficData || trafficData.length === 0) {
    return <div>N/A</div>;
  }

  const currentTraffic = trafficData[0].visits;
  const formattedTraffic = formatNumberAbbreviated(currentTraffic);

  return (
    <div>
      {formattedTraffic}{" "}
      <span className={getChangeClass(trafficChange)}>
        {formatChange(trafficChange)}
      </span>
    </div>
  );
};



const CompanyRow = ({
  company,
  expandedRow,
  toggleRow,
  onAddToWatchList,
  onDelete,
  onNotInterested,
  dateColumnStyle,
  selectedTab,
  colorSettings,
  columns,
  news = [],
  onNoteChange,
}) => {
  const rowStyle = getRowStyle(company, colorSettings);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [hasNews, setHasNews] = useState(false);
  const [relevantNews, setRelevantNews] = useState([]);

  // Add state and ref for tooltip positioning
  const [tooltipStyle, setTooltipStyle] = useState({});
  const newsIndicatorRef = useRef(null);

  // Add tooltip handling functions
  const updateTooltipPosition = () => {
    if (newsIndicatorRef.current) {
      const rect = newsIndicatorRef.current.getBoundingClientRect();
      setTooltipStyle({
        left: `${rect.left}px`,
        top: `${rect.top - 30}px`,
        opacity: 1,
        visibility: 'visible'
      });
    }
  };

  const handleMouseLeave = () => {
    setTooltipStyle(prev => ({
      ...prev,
      opacity: 0,
      visibility: 'hidden'
    }));
  };

  // Replace API call with local filtering
  useEffect(() => {
    if (!news?.length || !company?.tickerSymbol) {
      setHasNews(false);
      setRelevantNews([]);
      return;
    }

    const companySymbol = company.tickerSymbol.toUpperCase();
    const filteredNews = news.filter(article => 
      article.tickers?.some(ticker => 
        ticker.replace(/[$#]/g, '').toUpperCase() === companySymbol
      )
    ).slice(0, 5); // Keep top 5 most recent articles

    setHasNews(filteredNews.length > 0);
    setRelevantNews(filteredNews);
  }, [news, company.tickerSymbol]);

  // Render the news indicator in the row
  const renderNewsIndicator = () => {
    if (hasNews) {
      return (
        <div className="relative">
          <span 
            ref={newsIndicatorRef}
            className="news-indicator"
            onMouseEnter={updateTooltipPosition}
            onMouseLeave={handleMouseLeave}
          >
            <FaNewspaper className="inline-block text-blue-500" />
          </span>
          <Tooltip 
            id="news-tooltip"
            place="top"
            offset={5}
            delayHide={0}
            hideOnClick={true}
            clickable={false}
          >
            News available
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  const handleDropdownToggle = useCallback(
    (e) => {
      e.stopPropagation();
      setIsDropdownOpen((prev) => !prev);
    },
    []
  );

  // Add click outside handler to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleAddToWatchList = useCallback(
    (e) => {
      e.stopPropagation();
      onAddToWatchList(company);
      setIsDropdownOpen(false);
    },
    [company, onAddToWatchList]
  );

  const handleNotInterested = useCallback(
    (e) => {
      e.stopPropagation();
      if (onNotInterested) {
        onNotInterested(company.tickerSymbol);
        setIsDropdownOpen(false);
      }
    },
    [company, onNotInterested]
  );

  const handleDelete = useCallback(
    async (e) => {
      e.stopPropagation();
      try {
        await onDelete(company.tickerSymbol);
      } catch (error) {
        console.error("Error deleting company:", error);
      }
      setIsDropdownOpen(false);
    },
    [company.tickerSymbol, onDelete]
  );

  const renderLogo = () => {
    if (company.logo_url) {
      return (
        <img
          src={company.logo_url}
          alt={`${company.name} logo`}
          className="w-6 h-6 mr-2"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
              company.name
            )}&background=random`;
          }}
        />
      );
    } else {
      return (
        <div className="w-6 h-6 mr-2 bg-gray-200 flex items-center justify-center rounded-full">
          {company.name.charAt(0)}
        </div>
      );
    }
  };

  const iconStyle = {
    width: "16px",
    height: "16px",
    color: "#6B7280", // Tailwind's gray-500
  };

  // Function to render cell content based on column key
  const renderCellContent = (column) => {
    const { key, format } = column;

    // Add debug logging
    //console.log('Rendering cell for column:', key, 'Company data:', company);

    switch (key) {
      case "date":
        if (!company.earningsDate) {
          console.log('Missing earnings date for:', company.tickerSymbol);
        }
        return (
          <div className="text-sm">
            {formatDate(company.earningsDate)}
            <br />
            <EarningsBadge
              date={company.earningsDate}
              isEstimated={company.isEarningsDateEstimated}
            />
          </div>
        );

      case "name":
        return (
          <div className="flex items-center">
            {renderLogo()}
            <div className="min-w-0">
              <div className="flex items-start">
                <strong className="truncate">
                  {company.name}{" "}
                  <span className="text-gray-500">
                    ({company.tickerSymbol})
                    {hasNews && (
                      <span 
                        className="news-indicator ml-1"
                        data-tooltip-id="news-tooltip"
                      >
                        <FaNewspaper className="inline-block text-blue-500" />
                      </span>
                    )}
                  </span>
                </strong>
              </div>
              {company.website && (
                <a
                  href={company.website.startsWith("http") ? company.website : `https://${company.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline text-sm"
                >
                  {company.website}
                </a>
              )}
            </div>
            <div className="ml-auto relative company-dropdown" ref={dropdownRef}>
              <button
                className={`company-dropdown-button text-gray-600 hover:text-gray-800 focus:outline-none p-1 transition-colors duration-200 ease-in-out ${
                  isDropdownOpen ? "bg-gray-200" : ""
                }`}
                type="button"
                onClick={handleDropdownToggle}
                aria-label="Company options"
              >
                <FontAwesomeIcon icon={faEllipsisV} className="w-4 h-4" />
              </button>
              {isDropdownOpen && (
                <div className="company-dropdown-menu">
                  {selectedTab !== "watchlist" && (
                    <>
                      <button
                        className="company-dropdown-item watchlist"
                        onClick={handleAddToWatchList}
                      >
                        <FontAwesomeIcon icon={faStar} className="icon text-yellow-500" />
                        Add to Watch List
                      </button>
                      <button
                        className="company-dropdown-item not-interested"
                        onClick={handleNotInterested}
                      >
                        <FontAwesomeIcon icon={faBan} className="icon text-red-500" />
                        Not Interested
                      </button>
                    </>
                  )}
                  {selectedTab === "watchlist" && (
                    <button
                      className="company-dropdown-item delete"
                      onClick={handleDelete}
                    >
                      <FontAwesomeIcon icon={faTrash} className="icon text-red-500" />
                      Delete
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        );

      case "stockChange":
        return (
          <>
            {`${parseFloat(company.stockPrice).toFixed(2)} `}
            <span className={getChangeClass(company.stockChange)}>
              {formatChange(company.stockChange)}
            </span>
          </>
        );

      case "stockPrice":
        return (
          <>
            {company.stockPrice != null ? `$${company.stockPrice.toFixed(2)}` : 'N/A'}{" "}
            {company.regularMarketChangePercent != null && (
              <span className={getChangeClass(company.regularMarketChangePercent)}>
                {formatChange(company.regularMarketChangePercent)}
              </span>
            )}
          </>
        );

      case "trafficChange":
        return (
          <TrafficCell
            trafficData={company.trafficData}
            trafficChange={company.trafficChange}
          />
        );

      case "positionsPercentage":
        return (
          <>
            {company.positions}
            {company.positionsPercentage !== null && (
              <span className={getChangeClass(company.positionsPercentage)}>
                {` ${formatChange(company.positionsPercentage)}`}
              </span>
            )}
          </>
        );

      case "outlook":
        const outlookValue = company.outlook;
        if (outlookValue == null || isNaN(outlookValue)) {
          return "N/A";
        }
        return parseFloat(outlookValue).toFixed(2); // Display value between 0 and 1

      case "dividendYield":
        if (company.dividendYield == null || isNaN(company.dividendYield)) {
          return "N/A";
        }
        return `${company.dividendYield.toFixed(2)}%`; // No multiplication

      case "marketCap":
        if (company.marketCap == null || isNaN(company.marketCap)) {
          return "N/A";
        }
        return formatMarketCap(company.marketCap);

      case "actions":
        // Render the expand/collapse icon
        const isExpanded = expandedRow === company.tickerSymbol;
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              toggleRow(company.tickerSymbol);
            }}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            aria-label="Toggle Row Expansion"
          >
            {isExpanded ? (
              <FiChevronUp style={{ width: "16px", height: "16px" }} />
            ) : (
              <FiChevronDown style={{ width: "16px", height: "16px" }} />
            )}
          </button>
        );

      case "growth":
        if (company.revenueGrowth == null || isNaN(company.revenueGrowth)) {
          return "N/A";
        }
        return `${(company.revenueGrowth * 100).toFixed(2)}%`;

      case "shortFloat":
        if (company.shortPercentOfFloat == null || isNaN(company.shortPercentOfFloat)) {
          return "N/A";
        }
        return `${(company.shortPercentOfFloat * 100).toFixed(2)}%`;

      case "margins":
        if (company.grossMargins == null || company.operatingMargins == null) {
          return "N/A";
        }
        return (
          <span>
            {(company.grossMargins * 100).toFixed(1)}%{" "}
            <span className={getChangeClass(company.operatingMargins)}>
              {formatChange(company.operatingMargins * 100)}
            </span>
          </span>
        );

      case "beta":
        if (company.beta == null || isNaN(company.beta)) {
          return "N/A";
        }
        return company.beta.toFixed(2);

      case "pegRatio":
        if (company.pegRatio == null || isNaN(company.pegRatio)) {
          return "N/A";
        }
        return company.pegRatio.toFixed(2);

      case "technicalSignals":
        return <TechnicalSignalIcons signals={company.technicalSignals} />;

      case 'extendedHours':
        // If there's a preMarketPrice, show it, else if there's a postMarketPrice, show that
        if (company.preMarketPrice) {
          return (
            <>
              {company.preMarketPrice}{" "}
              <span className={getChangeClass(company.preMarketChangePercent)}>
                {formatChange(company.preMarketChangePercent)}
              </span>
            </>
          );
        } else if (company.postMarketPrice) {
          return (
            <>
              {company.postMarketPrice}{" "}
              <span className={getChangeClass(company.postMarketChangePercent)}>
                {formatChange(company.postMarketChangePercent)}
              </span>
            </>
          );
        } else {
          return "N/A";
        }

      case "industry":
        return company.industry || "N/A";

      case "notes":
        // Only show notes in watchlist
        if (selectedTab !== 'watchlist') {
          return (
            <div className="text-sm text-gray-400 italic">
              (Only available in watchlist)
            </div>
          );
        }
        return (
          <EditableNotes
            tickerSymbol={company.tickerSymbol}
            initialValue={company.notes || ""}
            onNoteChange={onNoteChange}
          />
        );

      default:
        let value = company[key];
        return value != null ? value : "N/A";
    }
  };

  return (
    <>
      <tr
        key={`${company.tickerSymbol}-main`}
        className="hover:bg-gray-50 cursor-pointer"
        style={rowStyle}
        onClick={() => toggleRow(company.tickerSymbol)}
      >
        {columns.map((column) => (
          <td
            key={column.key}
            className="py-2 px-4 border-b"
            style={column.key === 'date' ? dateColumnStyle : {}}
            data-column={column.key}
          >
            {renderCellContent(column)}
          </td>
        ))}
      </tr>
      {expandedRow === company.tickerSymbol && (
        <tr key={`${company.tickerSymbol}-expanded`}>
          <td colSpan={columns.length} className="p-4 bg-gray-100">
            {hasNews && (
              <div className="company-news-container mb-4">
                <div className="company-news-content">
                  <h3 className="company-news-header">Recent News</h3>
                  <div>
                    {relevantNews.map((article) => (
                      <a 
                        key={article.url}
                        href={article.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="company-news-item"
                      >
                        {article.imageUrl && (
                          <img
                            src={article.imageUrl}
                            alt=""
                            className="company-news-image"
                          />
                        )}
                        <div className="company-news-text">
                          <h4 className="company-news-title line-clamp-1">
                            {article.title}
                          </h4>
                          <div className="company-news-meta">
                            {article.source} • {new Date(article.publishedAt).toLocaleDateString()}
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <AiAnalysisPanel
              aiAnalysis={company.ai_analysis}
              stock={company}
            />
          </td>
        </tr>
      )}
      <Tooltip 
        id="news-tooltip"
        place="top"
        content="News available"
        className="z-[9999]"
        delayHide={0}
      />
    </>
  );
};

export default CompanyRow;
